import fetch from '@/utils/fetch';

export function getSvgIcons(url) {
    return fetch({
        url,
        method: 'get'
    });
}

export function trackHit(data) {
    return fetch({
        url: '/track/hit',
        method: 'post',
        data
    });
}

import store from '@/store';


/**
 * AlinT
 * Pentru erorile care vin direct din API.
 * NU MAI ESTE NEVOIE SA AFISAM SI IN COMPONENTA MESAJELE
 *
 * @export
 * @param {*} error
 */
export function handleApiError(error) {
	store.dispatch('snackbar/addMessage', {
		message: error.message || 'Eroare necunoscuta',
		type: 'error'
	});
}

/**
 * AlinT
 * Tip de eroare care nu va fi raportata catre sentry
 *
 * @export
 * @param {*} message
 * @param {*} [data={}]
 * @return {Error}
 */
export function LocalError(message, data = {}) {
	const error = Error.call(this, message);
	error.name = 'LocalError';
	error.data = data;
	return error;
}
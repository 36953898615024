import Cookies from 'js-cookie';

const TokenKey = 'celmptoken';
const RememberKey = 'celmpremember';

export function getToken() {
	return Cookies.get(TokenKey);
}

export function setToken(token) {
	return Cookies.set(TokenKey, token);
}

export function removeToken() {
	return Cookies.remove(TokenKey);
}

export function setRememberMe() {
	return Cookies.set(RememberKey, 1, {
		expires: 3
	});
}

export function removeRememberMe() {
	return Cookies.remove(RememberKey);
}

export function getRememberMe() {
	return Cookies.get(RememberKey) !== 'undefined' && Cookies.get(RememberKey) === '1' ? 1 : 0;
}
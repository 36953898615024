import axios from 'axios';
import Vue from 'vue';
import store from '@/store';
import { getToken } from '@/utils/auth';
import { generateUID } from '@/utils/index';
import isObject from 'lodash.isobject';
import isArray from 'lodash.isarray';
import has from 'lodash.has';
import delay from 'lodash.delay';
import Router from '../router';
import i18n from '@/lang';
import jsCookie from 'js-cookie';
import { handleApiError, LocalError } from '@/utils/errorHandler';

const urlConstructor = () => {
    const protocol = location.protocol === 'https:' ? 'https' : 'https';
    let dynamicUrl = '';
    switch (true) {
        case typeof window.localStorage.server !== 'undefined':
            dynamicUrl = window.localStorage.server;
            break;

        // case (typeof store.state.app.hostnameCase[hostname] !== 'undefined'):
        // 	dynamicUrl = store.state.app.hostnameCase[hostname]
        // 	break

        default:
            dynamicUrl = 'api-mp.cel.ro';
    }

    // let dynamicUrl = typeof window.localStorage.server === 'undefined' || typeof window.localStorage.getItem('server') === 'null' || typeof window.localStorage.getItem('server') === 'undefined' ? (window.location.hostname === '192.168.0.119' ? '192.168.0.89' : window.location.hostname) : (window.location.hostname === 'marketplace.cel.ro' || window.location.hostname === 'demo.cel.ro' ? window.location.hostname : window.localStorage.getItem('server'))
    // console.log(protocol)
    // console.log(dynamicUrl)
    return protocol + '://' + dynamicUrl + '/market_api/';
};

export const service = axios.create({
    baseURL: urlConstructor(),
    timeout: 900000,
    headers: {
        'content-type': 'multipart/form-data'
    }
});

service.interceptors.request.use(
    (config) => {
        let toggleException = false;
        for (const e in store.state.app.loadingExceptions) {
            if (config.url.indexOf(store.state.app.loadingExceptions[e]) !== -1) {
                toggleException = true;
            }
        }

        let cancelException = false;
        store.state.app.cancelExceptions.map((method) => {
            if (config.url.includes(method)) {
                cancelException = true;
            }
        });

        if (!toggleException) store.dispatch('app/ToggleLoading', true);
        if (store.getters['user/token']) {
            config.headers['AUTH'] = 'Bearer ' + getToken();
            config.headers['REMEMBER'] = store.getters['user/remember'];
        }
        const userToken = jsCookie.get('USERTOKEN');
        if (userToken != null) {
            config.headers['USERTOKEN'] = userToken;
        }

        if (store.getters['user/languageId']) {
            config.headers['LANGUAGEID'] = store.getters['user/languageId'];
        }

        if (
            typeof config.data !== 'undefined' &&
            typeof config.data.isFeedback !== 'undefined' &&
            config.data.isFeedback &&
            config.data.form instanceof FormData
        ) {
            delete config.data.isFeedback;
            config.baseURL = config.baseURL.replace('market_api', 'feedback');
            // config.baseURL = config.baseURL.replace('192.168.0.89', 'marketplace.cel.ro')
            // config.baseURL = config.baseURL.replace('http', 'https')
            config.data = config.data.form;
        } else {
            if (config.data !== null && !(config.data instanceof FormData)) {
                const form = new FormData();
                for (const key in config.data) {
                    if (typeof config.data[key] === 'undefined') continue;
                    form.append(
                        key,
                        isObject(config.data[key] || isArray(config.data[key]))
                            ? JSON.stringify(config.data[key])
                            : config.data[key]
                    );
                }
                form.append('api_version', 'GUI');

                config.data = form;
            } else if (config.data instanceof FormData) {
                config.data.append('api_version', 'GUI');
            }
        }

        if (!cancelException) {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            const UID = generateUID();
            config.cancelToken = source.token;
            config.headers['Req-id'] = UID;
            store.commit('app/ADD_REQ', {
                uid: UID,
                token: source
            });
        }

        return config;
    },
    (error) => {
        // Do something with request error
        // console.log(error) // for debug
        Promise.reject(error);
    }
);

service.interceptors.response.use(
    (response) => {
        const res = response.data;
        store.dispatch('app/ToggleLoading', false);
        if (typeof res.reqid !== 'undefined') {
            store.commit('app/REMOVE_REQ', res.reqid);
        }

        switch (true) {
            case res == null:
                throw new Error('A intervenit o eroare! Contactati departamentul tehnic.');

            case typeof res.error === 'undefined':
                if (res != null && Array.isArray(res) && res.indexOf('FACTURA FISCALA') !== -1) {
                    return res;
                } else {
                    throw new LocalError(i18n.t('layout.req-1'));
                }

            // Status nou pentru erori de restrictii
            case parseInt(res.error) === 423:
                Router.push({
                    path: '/'
                }).catch(() => {});
                throw new LocalError(res.message);

            // Status nou pentru erori de sesiune
            case parseInt(res.error) === 403:
                delay(() => {
                    store.dispatch('user/LogOut', {
                        withRequest: false
                    });
                    Router.push('/login').catch(() => {});
                }, 1000);
                throw new LocalError(res.message);
            // Status nou pentru erori de logare
            case parseInt(res.error) === 405:
                throw new LocalError(res.message);

            case parseInt(res.tokenStatus) < 1:
                delay(() => {
                    store.dispatch('user/LogOut', {
                        withRequest: false
                    });
                    Router.push('/login').catch(() => {});
                }, 1000);
                throw new LocalError(i18n.t('layout.req-2'));

            case parseInt(res.error) === 500 && has(res.message, 'missingInfo'):
                Router.push({
                    path: '/cont/informatii'
                }).catch(() => {});
                throw new LocalError(res.message.info || res.message);

            case parseInt(res.error) === 302:
                Router.push('/login').catch(() => {});
                throw new LocalError(res.message);

            case parseInt(res.error) === 504:
                const path = res.message.substring(res.message.indexOf('|') + 1);
                Router.push({
                    path: '/' + path
                }).catch(() => {});
                break;

            case parseInt(res.error) === 1:
                if (typeof res.message === 'object') return response.data;
                if (parseInt(res.token) < 1) {
                    setTimeout(() => {
                        store.dispatch('user/LogOut', {
                            withRequest: false
                        });
                    }, 500);
                    throw new LocalError(res.message);
                }

                throw new LocalError(res.message.toString());

            case parseInt(res.error) > 1:
                return response.data;

            case res.message === null:
                return response.data;

            case parseInt(res.message.success) === 1 || res.message.success === true:
                return response.data;

            default:
                return response.data;
        }
    },
    (error) => {
        store.dispatch('app/ToggleLoading', false);
        if (error instanceof axios.Cancel || (error != null && error.message === 'Cancel')) {
            const BreakException = {
                canceled: 1
            };
            throw BreakException;
        }
        if (error.message === 'Network Error') {
            throw new LocalError(
                'Eroare la conectare! Daca aveti antivirus instalat va rugam sa adaugati platforma la exceptii!'
            );
        }
        throw error;
    }
);

export default function fetcher(...params) {
    return new Promise(function promiseFn(resolve, reject) {
        service(...params)
            .then(function resFn(res) {
                return resolve(res);
            })
            .catch(function errFn(error) {
                /**
                 * Don't register cancel request errors
                 */
                if (error && error.canceled) return reject(error);
                Vue.prototype.$reportToSentry(error, {
                    extra: {
                        fn: 'apiError'
                    }
                });
                return handleApiError(error), reject(error);
            });
    });
}

import Cookies from 'js-cookie';

import { getLanguages, setLanguage } from '@/api/front'
import { getCountriesList, getCountiesList, getCitiesList } from '@/api/cont';

export default {
	ToggleSideBar: ({
		commit
	}) => {
		commit('TOGGLE_SIDEBAR');
	},
	CloseSideBar: ({
		commit
	}, {
		withoutAnimation
	}) => {
		commit('CLOSE_SIDEBAR', withoutAnimation);
	},
	ToggleDevice: ({
		commit
	}, device) => {
		commit('TOGGLE_DEVICE', device);
	},
	ToggleLoading: ({
		commit
	}, status) => {
		commit('TOGGLE_LOADING', status);
	},
	setFocusOnApp: ({
		commit
	}, status) => {
		commit('SET_FOCUS_APP', status);
	},
	SetNotificationsUnreadNumber: ({
		commit
	}, number) => {
		commit('SET_NOTIFICATIONS_UNREAD_NUMBER', number);
	},
	SetBadges: ({
		commit
	}, badges) => {
		commit('SET_BADGES', badges);
	},
	ResetAppState: ({
		commit
	}) => {
		commit('RESET_STATE');
	},
	setNewFeaturesCookie: ({
		commit
	}) => {
		commit('SET_FEATURES_COOKIE');
	},
	ChangeLanguage: ({
		commit
	}, lang) => {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await setLanguage({
					language: lang
				})
				if (res.message == null) return reject(new Error())
				commit('SET_LANGUAGE', lang)
				Cookies.set('lang', lang)
				return resolve()
			} catch (err) {
				return reject(err)
			}
		})
	},
	UpdateRouteParams: ({
		commit
	}, {
		path,
		params
	}) => {
		commit('SET_QP', {
			path,
			params
		});
	},
	ToggleFeedBackModal: ({
		commit
	}, status) => {
		commit('TOGGLE_FEEDBACK', status);
	},
	RemovePendingReqs: ({
		commit,
		state
	}) => {
		return new Promise((resolve) => {
			for (const i in state.requests) {
				state.requests[i].token.cancel();
				commit('REMOVE_REQ', state.requests[i].uid);
			}
			return resolve();
		});
	},
	SetProductsList: ({
		commit,
		state
	}, status) => {
		Cookies.set('expandProductsList', status, {
			expires: 30
		});
		commit('SET_PRODUCTS_LIST_EXPAND', status);
	},
	GetLanguages: () => {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await getLanguages()
				if (res.message == null) return reject(new Error())
				return resolve(res)
			} catch (err) {
				return reject(err)
			}
		})
	},
	GetCountriesList: () => {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await getCountriesList()
				if (res.message == null) return reject(new Error())
				return resolve(res)
			} catch (err) {
				return reject(err)
			}
		})
	},
	GetCountiesList: () => {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await getCountiesList()
				if (res.message == null) return reject(new Error())
				return resolve(res)
			} catch (err) {
				return reject(err)
			}
		})
	},
	GetCitiesList: ({ state }, data) => {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await getCitiesList(data)
				if (res.message == null) return reject(new Error())
				return resolve(res)
			} catch (err) {
				return reject(err)
			}
		})
	}
};
const Layout = () => import('@/views/layout/Layout');

const InformatiiPage = () => import('@/views/cont/informatii/index');
const CurieriPage = () => import('@/views/cont/curieri');
const TaxePage = () => import('@/views/cont/taxaTransport');
const DocumentePage = () => import('@/views/cont/documente');
const CategoriiPage = () => import('@/views/cont/categoryaccess');
const Feedback = () => import('@/views/cont/feedback-afiliati');
const NotificariPage = () => import('@/views/cont/notificari-afiliati');

export default [
    {
        path: '/cont',
        component: Layout,
        redirect: '/cont/informatii',
        name: 'Cont',
        meta: {
            tour: false,
            icon: 'profile',
            order: 1,
            title: 'account-main',
            badge: ['docs', 'acts'],
            demoRestricted: false,
            module: 'account',
        },
        children: [
            {
                path: 'informatii',
                name: 'Informatii',
                component: InformatiiPage,
                meta: {
                    tour: false,
                    title: 'account-info',
                    icon: 'profile',
                    badge: 'acts',
                    demoRestricted: false,
                    module: 'account.account-info',
                },
            },
            // {
            // 	path: 'adrese',
            // 	name: 'Adrese ridicare',
            // 	component: _import('cont/adrese'),
            // 	meta: {
            // 		tour: true,
            // 		title: 'account-addresses',
            // 		icon: 'compass2',
            // 		demoRestricted: false,
            // 		module: 'account.pickup'
            // 	}
            // },
            {
                path: 'curieri',
                name: 'Conturi curieri',
                component: CurieriPage,
                meta: {
                    tour: true,
                    title: 'account-couriers',
                    icon: 'truck',
                    demoRestricted: false,
                    module: 'account.couriers',
                },
                children: [],
            },
            {
                path: 'taxe',
                name: 'Taxe',
                component: TaxePage,
                meta: {
                    tour: false,
                    title: 'account-taxes',
                    icon: 'coins',
                    demoRestricted: false,
                    module: 'account.account-taxes',
                },
            },
            {
                path: 'docs',
                name: 'Documente',
                component: DocumentePage,
                meta: {
                    tour: false,
                    title: 'account-docs',
                    isNew: true,
                    badge: 'docs',
                    icon: 'file-text',
                    demoRestricted: true,
                    module: 'account.account-docs',
                },
            },
            {
                path: 'categoryaccess',
                name: 'Acces categorii',
                component: CategoriiPage,
                meta: {
                    tour: false,
                    title: 'account-categaccess',
                    icon: 'locked',
                    demoRestricted: false,
                    module: 'account.account-categories',
                },
            },
            {
                path: 'profil-public',
                name: 'Profil public',
                redirect: '/cont/informatii',
                meta: {
                    hidden: true,
                    module: 'account.account-profile',
                },
            },
            {
                path: 'feedback-afiliati',
                name: 'Feedback afiliati',
                component: Feedback,
                meta: {
                    tour: false,
                    title: 'feedback-afiliati',
                    icon: 'file',
                    demoRestricted: true,
                    module: 'account.account-feedback',
                },
            },
            {
                path: 'notificari-afiliati',
                name: 'Notificari',
                component: NotificariPage,
                meta: {
                    tour: false,
                    title: 'notificari-afiliati',
                    icon: 'bell',
                    demoRestricted: true,
                    module: 'account.account-notifications',
                },
            },
        ],
    },
];

const Layout = () => import('@/views/layout/Layout');
const ListaPage = () => import('@/views/produse/lista');
const FamiliiPage = () => import('@/views/produse/familii');
const CampaniiPage = () => import('@/views/produse/campanii');
const InasteptarePage = () => import('@/views/produse/inasteptare');
const RespinsePage = () => import('@/views/produse/respinse');
const ReviewsPage = () => import('@/views/produse/reviews/index');
const CampaniePage = () => import('@/views/produse/campanie');
const ProdusPage = () => import('@/views/produse/produs');
const ProdusInAsteptarePage = () => import('@/views/produse/produs-asteptare');
const FamiliePage = () => import('@/views/produse/familie');
const ClonePage = () => import('@/views/produse/clone');
const DiferentePretPage = () => import('@/views/produse/diferente-pret');

export default [
    {
        path: '/produse',
        component: Layout,
        redirect: '/produse/lista',
        name: 'Produse',
        meta: {
            icon: 'table',
            tour: false,
            order: 5,
            title: 'products-main',
            badge: ['refused_prods', 'waiting_prods', 'price_diff', 'products_reviews'],
            demoRestricted: false,
            module: 'products'
        },
        children: [
            {
                path: 'lista',
                name: 'Lista produse',
                component: ListaPage,
                meta: {
                    icon: 'list',
                    tour: true,
                    title: 'products-list',
                    demoRestricted: false,
                    module: 'products.products-list'
                },
                props: (route) => ({
                    filterQuery: {
                        manufacturer: route.query.manufacturer,
                        keyword: route.query.keyword,
                        categories_id: route.query.categories_id,
                        vendor_ext_id: route.query.vendor_ext_id,
                        start: route.query.start,
                        products_model: route.query.products_model,
                        status: route.query.status,
                        status_site: route.query.status_site
                    }
                })
            },
            {
                path: 'familii',
                name: 'Familii produse',
                component: FamiliiPage,
                meta: {
                    icon: 'make-group',
                    tour: false,
                    title: 'products-families',
                    demoRestricted: false,
                    module: 'products.products-families'
                }
            },
            {
                path: 'campanii',
                name: 'Campanii',
                component: CampaniiPage,
                meta: {
                    icon: 'seo',
                    tour: true,
                    title: 'products-campaigns',
                    demoRestricted: false,
                    module: 'products.products-campaigns'
                },
                props: (route) => ({
                    filterQuery: {
                        search: route.query.search,
                        minDate: route.query.minDate,
                        maxDate: route.query.maxDate,
                        start: route.query.start
                    }
                })
            },
            {
                path: 'in-asteptare',
                name: 'In asteptare',
                component: InasteptarePage,
                meta: {
                    icon: 'clipboard1',
                    tour: false,
                    title: 'products-waiting',
                    badge: 'waiting_prods',
                    demoRestricted: false,
                    module: 'products.products-waiting'
                }
            },
            {
                path: 'respinse',
                name: 'Respinse',
                component: RespinsePage,
                meta: {
                    icon: 'clipboard',
                    tour: false,
                    title: 'products-rejected',
                    badge: 'refused_prods',
                    demoRestricted: false,
                    module: 'products.products-rejected'
                }
            },
            {
                path: 'reviews',
                name: 'Recenzii',
                props: true,
                component: ReviewsPage,
                meta: {
                    tour: false,
                    title: 'products-reviews-single',
                    hidden: false,
                    icon: 'message-circle',
                    badge: 'products_reviews',
                    module: 'products.products-reviews'
                }
            },
            {
                path: 'campanie/:id',
                name: 'Campanie',
                props: true,
                component: CampaniePage,
                meta: {
                    tour: true,
                    title: 'products-campaigns-single',
                    hidden: true,
                    module: 'products.products-campaigns'
                }
            },
            {
                path: 'produs/:pid/:vendorId?',
                name: 'Produs',
                props: true,
                component: ProdusPage,
                meta: {
                    tour: true,
                    title: 'products-single',
                    hidden: true,
                    module: 'products.products-list'
                }
            },
            {
                path: 'produs-asteptare/:pid',
                name: 'Produs asteptare',
                props: true,
                component: ProdusInAsteptarePage,
                meta: {
                    tour: false,
                    title: 'products-waiting-single',
                    hidden: true,
                    module: 'products.products-waiting'
                }
            },
            {
                path: 'familie/:famId',
                name: 'Familie produse',
                props: true,
                component: FamiliePage,
                meta: {
                    tour: false,
                    title: 'products-families-single',
                    hidden: true,
                    module: 'products.products-families'
                }
            },
            {
                path: 'clone/:pid/:vendorId?',
                name: 'Clonare produs',
                props: true,
                component: ClonePage,
                meta: {
                    tour: false,
                    title: 'products-single',
                    hidden: true,
                    module: 'products.products-clone'
                }
            },
            {
                path: 'diferente-pret',
                name: 'Diferente pret',
                component: DiferentePretPage,
                meta: {
                    icon: 'stats',
                    tour: false,
                    title: 'reports-pricediff',
                    badge: 'price_diff',
                    demoRestricted: true,
                    module: 'products.products-diffs'
                }
            }
        ]
    }
];

const Layout = () => import('@/views/layout/Layout');
const AdaugarePage = () => import('@/views/publicare/adaugare');
const OfertaPage = () => import('@/views/publicare/oferta');
const ImportPage = () => import('@/views/publicare/import');
const FeeduriPage = () => import('@/views/publicare/feeduri');
const BonusPage = () => import('@/views/publicare/import-bonus');
const FeedPage = () => import('@/views/publicare/feed');
const IstoricFeedPage = () => import('@/views/publicare/istoric-feed');

export default [
    {
        path: '/publicare',
        component: Layout,
        redirect: '/publicare/adauga-produs',
        name: 'Publicare',
        meta: {
            icon: 'box-remove',
            tour: false,
            order: 5,
            title: 'import-main',
            demoRestricted: false,
            module: 'publish'
        },
        children: [
            {
                path: 'adauga-produs',
                name: 'Adauga produs',
                component: AdaugarePage,
                meta: {
                    icon: 'folder-plus',
                    tour: true,
                    title: 'import-product',
                    demoRestricted: false,
                    module: 'publish.publish-add'
                }
            },
            {
                path: 'adauga-oferta',
                name: 'Adauga oferta',
                component: OfertaPage,
                meta: {
                    icon: 'folder-upload',
                    tour: false,
                    title: 'import-offer',
                    demoRestricted: true,
                    module: 'publish.publish-offer'
                }
            },
            {
                path: 'import-xls',
                name: 'Import XLS',
                component: ImportPage,
                meta: {
                    icon: 'box-remove',
                    tour: true,
                    title: 'import-xls',
                    demoRestricted: true,
                    module: 'publish.publish-xls'
                },
                props: (route) => ({
                    filterQuery: {
                        minDate: route.query.minDate,
                        maxDate: route.query.maxDate,
                        start: route.query.start
                    }
                })
            },
            {
                path: 'feeduri',
                name: 'Feeduri',
                component: FeeduriPage,
                meta: {
                    icon: 'rss',
                    tour: false,
                    yt: {
                        active: true,
                        link: 'https://www.youtube.com/embed/k_VGF5Bu_W4'
                    },
                    title: 'import-feeds',
                    demoRestricted: true,
                    module: 'publish.publish-feeds'
                },
                props: (route) => ({
                    filterQuery: {
                        name: route.query.name,
                        status: route.query.status,
                        start: route.query.start
                    }
                })
            },
            {
                path: 'import-bonus',
                name: 'Import bonus',
                component: BonusPage,
                meta: {
                    icon: 'star',
                    tour: true,
                    title: 'import-bonus',
                    demoRestricted: true,
                    module: 'publish.publish-bonus'
                },
                props: (route) => ({
                    filterQuery: {
                        minDate: route.query.minDate,
                        maxDate: route.query.maxDate,
                        start: route.query.start
                    }
                })
            },
            {
                path: 'feed/:id?',
                name: 'Feed',
                component: FeedPage,
                props: true,
                meta: {
                    icon: 'rss',
                    tour: false,
                    title: 'single-feed',
                    hidden: true,
                    yt: {
                        active: true,
                        link: 'https://www.youtube.com/embed/k_VGF5Bu_W4'
                    },
                    module: 'publish.publish-feeds'
                }
            },
            {
                path: 'istoric-feed/:id',
                name: 'IstoricFeed',
                component: IstoricFeedPage,
                props: (route) => ({
                    filterQuery: {
                        startDate: route.query.startDate,
                        status: route.query.status,
                        request_id: route.query.request_id,
                        start: route.query.start
                    },
                    id: route.params.id
                }),
                meta: {
                    icon: 'rss',
                    tour: false,
                    title: 'istoric-feed',
                    hidden: true,
                    yt: {
                        active: true,
                        link: 'https://www.youtube.com/embed/k_VGF5Bu_W4'
                    },
                    module: 'publish.publish-feeds'
                }
            }
        ]
    }
];

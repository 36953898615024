import { trackHit } from '@/api/utils';

const EXPCETIONS = ['/', '/dashboard/index', '/login', '/register', '/lead'];
export default async function track(data) {
    let { path } = data;
    const parts = path.split('/');
    if (parts.length > 3) {
        const [one, two, three, ..._] = parts;
        path = [one, two, three].join('/');
    }
    if (EXPCETIONS.includes(path)) return Promise.resolve();
    return trackHit({ path });
}

const Layout = () => import('@/views/layout/Layout');
const ProdusePage = () => import('@/views/rapoarte/produse');
const PlatiCardPage = () => import('@/views/rapoarte/plati-card');
const ComenziPage = () => import('@/views/rapoarte/raport-comenzi');
export default [
    {
        path: '/rapoarte',
        component: Layout,
        redirect: '/rapoarte/produse',
        name: 'Rapoarte',
        meta: {
            icon: 'tubiao',
            tour: false,
            order: 4,
            title: 'reports-main',
            demoRestricted: true,
            module: 'reports'
        },
        children: [
            {
                path: 'produse',
                name: 'Rapoarte produse',
                component: ProdusePage,
                meta: {
                    icon: 'pie-chart',
                    tour: false,
                    title: 'reports-products',
                    demoRestricted: true,
                    module: 'reports.reports-products'
                },
                props: (route) => ({
                    filterQuery: {
                        minDate: route.query.minDate,
                        maxDate: route.query.maxDate,
                        start: route.query.start
                    }
                })
            },
            {
                path: 'plati-card',
                name: 'Plati cu cardul',
                component: PlatiCardPage,
                meta: {
                    icon: 'stats-bars2',
                    tour: false,
                    title: 'reports-card-payments',
                    demoRestricted: true,
                    module: 'reports.reports-card'
                }
            },
            {
                path: 'raport-comenzi',
                name: 'Raport comenzi',
                component: ComenziPage,
                meta: {
                    icon: 'compass2',
                    tour: false,
                    title: 'reports-comenzi',
                    demoRestricted: true,
                    module: 'reports.reports-orders'
                },
                props: (route) => ({
                    filterQuery: {
                        dateMin: route.query.dateMin,
                        dateMax: route.query.dateMax,
                        selectedStatus: route.query.selectedStatus
                    }
                })
            }
        ]
    }
];

import fetch from '@/utils/fetch';

export function getNotifications() {
	return fetch({
		url: '/admininfo/getNotifications',
		method: 'post',
	});
}

export function markAsSeen(data) {
	return fetch({
		url: '/admininfo/markAsSeen',
		method: 'post',
		data: data
	});
}

export function markAllAsSeen() {
	return fetch({
		url: '/admininfo/markAllAsSeen',
		method: 'post'
	});
}


export function setCloudMessagingToken(data) {
	return fetch({
		url: '/admininfo/setCloudMessagingToken',
		method: 'post',
		data
	});
}

export function getLatestNotifications() {
	return fetch({
		url: '/admininfo/getLatestNotifications',
		method: 'post'
	});
}

export function markNotificationListAsSeen(data) {
	return fetch({
		url: '/admininfo/markNotificationListAsSeen',
		method: 'post',
		data
	});
}

export function getNotificationsList(data) {
	return fetch({
		url: '/admininfo/getNotificationsList',
		method: 'post',
		data
	});
}
import Cookies from 'js-cookie';
import {
	initialState
} from './index';
export default {
	TOGGLE_SIDEBAR: (state) => {
		if (state.sidebar.opened) {
			Cookies.set('sidebarStatus', 1);
		} else {
			Cookies.set('sidebarStatus', 0);
		}
		state.sidebar.opened = !state.sidebar.opened;
		state.sidebar.withoutAnimation = false;
	},
	CLOSE_SIDEBAR: (state, withoutAnimation) => {
		Cookies.set('sidebarStatus', 1);
		state.sidebar.opened = false;
		state.sidebar.withoutAnimation = withoutAnimation;
	},
	TOGGLE_LOADING: (state, status) => {
		state.bodyLoading = status;
	},
	SET_FOCUS_APP: (state, status) => {
		state.hasFocus = status;
	},
	TOGGLE_DEVICE: (state, device) => {
		state.device = device;
	},
	SET_NOTIFICATIONS_UNREAD_NUMBER: (state, number) => {
		state.notifications.unread = number;
	},
	SET_BADGES: (state, badges) => {
		state.badges = badges;
	},
	RESET_APP_STATE: (state, rootState) => {
		// App state
		const iState = initialState();
		for (const prop in state) {
			if (typeof iState[prop] !== 'undefined') {
				state[prop] = iState[prop];
			}
		}
	},
	SET_FEATURES_COOKIE: (state, cookie) => {
		Cookies.set('newFeatures', initialState.featuresKey, {
			expires: 30
		});
		state.features = initialState.featuresKey;
	},
	SET_LANGUAGE: (state, lang) => {
		state.lang = lang;
	},
	SET_QP: (state, data) => {
		state.queryParams[data.path] = data.params;
	},
	TOGGLE_FEEDBACK: (state, status) => {
		state.feedbackActive = status;
	},
	SET_SUPPLIER_CATEGS: (state, data) => {
		state.supplierCategories = data;
	},
	SET_ALREADY_CALL: (state, status) => {
		state.alreadyCalling = status;
	},
	ADD_REQ: (state, req) => {
		state.requests.push(req);
	},
	REMOVE_REQ: (state, id) => {
		const index = state.requests.findIndex((i) => i.uid === id);
		if (index > -1) {
			state.requests.splice(index, 1);
		}
	},
	ENABLE_VIDEO_INSTRUCTIONS: (state, link) => {
		state.videoInstructions.active = true;
		state.videoInstructions.link = link;
	},
	DISABLE_VIDEO_INSTRUCTIONS: (state) => {
		state.videoInstructions.active = false;
		state.videoInstructions.link = '';
	},
	SET_PRODUCTS_LIST_EXPAND: (state, status) => {
		state.expandProductsList = status
	},
	SET_LANGUAGES: (state, data) => {
		state.languages = data
	}
};
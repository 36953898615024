import Vue from 'vue';
import Vuex from 'vuex';
import {
	createSnackbarStore
} from './modules/snackbar/index.js';

import {
	createAppStore
} from './modules/app/index.js';
import {
	createUserStore
} from './modules/user/index.js';

import {
	createNotificationsStore
} from './modules/notifications/index.js';

if (!window.Vuex) Vue.use(Vuex);
import pathify from 'vuex-pathify';

const store = new Vuex.Store({
	plugins: [pathify.plugin],
	modules: {
		snackbar: createSnackbarStore(),
		app: createAppStore(),
		user: createUserStore(),
		notifications: createNotificationsStore()
	},
	strict: true
});

export default store;
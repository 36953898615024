export default {
    token: (state) => state.token,
    avatar: (state) => state.avatar,
    userName: (state) => {
        return typeof state.info.name !== 'undefined' ? state.info.name : 'UNKN';
    },
    userKey: (state) => (typeof state.info.key !== 'undefined' ? state.info.key : ''),
    userUsername: (state) => {
        return state.info.username != null ? state.info.username : '';
    },
    userEmail: (state) => {
        return typeof state.info.email !== 'undefined' ? state.info.email : '';
    },
    userId: (state) => {
        return typeof state.info.id !== 'undefined' ? state.info.id : '';
    },
    chatEnabled: (state) => {
        const affiliateChat = state.info.chatEnabled != null ? parseInt(state.info.chatEnabled) : 0;
        const globalChat = state.info.global_chat != null ? parseInt(state.info.global_chat) : 0;
        return affiliateChat > 0 && globalChat > 0;
    },
    roles: (state) => state.roles,
    remember: (state) => state.remember,
    feedbackActiv: (state) => state.feedback === 1,
    hasRestrictions: (state) =>
        state.restrictions &&
        state.restrictions.filter((restriction) => !restriction.hidden && !restriction.isPending).length > 0,
    hasPendingRestrictions: (state) =>
        state.restrictions && state.restrictions.some((restriction) => !restriction.hidden && restriction.isPending),
    hasSiteUrl: (state) => state.info != null && state.info.siteUrl != null && state.info.siteUrl !== '',
    linkOferta: (state) => {
        return state.info != null && state.info.linkOferta != null ? state.info.linkOferta : '';
    },
    isDemo: (state) => (state.info && state.info.isDemo ? 1 : 0),
    userHasInfoLoaded: (state) => state.infoLoaded,
    languageId: (state) => (state.info && state.info.language_id ? state.info.language_id : 0),
    needsToSignGDPR: (state) => state.info && state.info.gdpr == 0,
    needsToSignTOS: (state) => state.info && state.info.tos == 0,
    isExternal: (state) => state.info && state.info.isExternal == 1,
    allowOwnCourierAwb: (state) => state.info && state.info.allowOwnCourierAwb == 1,
};

/**
 * Created by jiachenpan on 16/11/18.
 */
import isObject from 'lodash.isobject';
import isArray from 'lodash.isarray';

export function parseTime(time, cFormat) {
    if (arguments.length === 0) {
        return null;
    }
    const format = cFormat || '{y}-{m}-{d} {h}:{i}:{s}';
    let date;
    if (typeof time === 'object') {
        date = time;
    } else {
        if (('' + time).length === 10) time = parseInt(time) * 1000;
        date = new Date(time);
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay(),
    };
    const timeStr = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key];
        if (key === 'a') return ['一', '二', '三', '四', '五', '六', '日'][value - 1];
        if (result.length > 0 && value < 10) {
            value = '0' + value;
        }
        return value || 0;
    });
    return timeStr;
}

export function formatTime(time, option) {
    time = +time * 1000;
    const d = new Date(time);
    const now = Date.now();

    const diff = (now - d) / 1000;

    if (diff < 30) {
        return '刚刚';
    } else if (diff < 3600) {
        // less 1 hour
        return Math.ceil(diff / 60) + '分钟前';
    } else if (diff < 3600 * 24) {
        return Math.ceil(diff / 3600) + '小时前';
    } else if (diff < 3600 * 24 * 2) {
        return '1天前';
    }
    if (option) {
        return parseTime(time, option);
    } else {
        return d.getMonth() + 1 + '月' + d.getDate() + '日' + d.getHours() + '时' + d.getMinutes() + '分';
    }
}

export function charactPriority() {
    return 19;
}

export function setParams(data) {
    const form = new FormData();
    for (const key in data) {
        // console.log(data[key]);
        // console.log(JSON.stringify(data[key]));
        form.append(key, isObject(data[key] || isArray(data[key])) ? JSON.stringify(data[key]) : data[key]);
    }
    return form;
}

export function parseCategories(categories) {
    const parsedCategories = [];

    function recursiveCategorieParsing(categories) {
        const children = [];
        for (const i in categories) {
            const cat = {};
            if (typeof categories[i] === 'object') {
                cat.label = i.split('_')[0];
                cat.value = i.split('_')[1];
                cat.children = recursiveCategorieParsing(categories[i]);
            } else {
                cat.label = i.split('_')[0];
                cat.value = categories[i];
            }
            children.push(cat);
        }
        return children;
    }

    for (const i in categories) {
        const cat1 = {};
        cat1.label = i.split('_')[0];
        cat1.value = i.split('_')[1];
        cat1.children = recursiveCategorieParsing(categories[i]);
        parsedCategories.push(cat1);
    }
    return parsedCategories;
}

// export function parseCategories(categories, rec = false) {
//     let parsedCategories = []
//
//     for (let i in categories) {
//         let cat1 = {}
//         cat1.label = i.split('_')[0]
//         cat1.value = i.split('_')[1]
//         cat1.children = []
//         for(let j in categories[i]) {
//             let cat2 = {}
//             if(typeof categories[i][j] === 'object') {
//                 cat2.label = j.split('_')[0]
//                 cat2.value = j.split('_')[1]
//                 cat2.children = []
//                 for(let k in categories[i][j]) {
//                     let cat3 = {}
//                     if(typeof categories[i][j][k] === 'object') {
//                         cat3.label = k.split('_')[0]
//                         cat3.value = k.split('_')[1]
//                         cat3.children = []
//                         for(let o in categories[i][j][k]) {
//                             let cat4 = {}
//                             cat4.label = o.split('_')[0]
//                             cat4.value = categories[i][j][k][o]
//                             cat3.children.push(cat4)
//                         }
//                     }else {
//                         cat3.label = k.split('_')[0]
//                         cat3.value = categories[i][j][k]
//                     }
//                     cat2.children.push(cat3)
//                 }
//             }else {
//                 cat2.label = j.split('_')[0]
//                 cat2.value = categories[i][j]
//             }
//             cat1.children.push(cat2)
//         }
//         parsedCategories.push(cat1)
//     }
//     console.log(parsedCategories);
//     return parsedCategories
// }

export function getCategoryName(catId, obj) {
    for (const i in obj) {
        for (const j in obj[i].children) {
            if (parseInt(catId) === parseInt(obj[i].children[j].value)) return obj[i].children[j].label;
            if (typeof obj[i].children[j].children !== 'undefined') {
                for (const k in obj[i].children[j].children) {
                    if (parseInt(catId) === parseInt(obj[i].children[j].children[k].value))
                        return obj[i].children[j].children[k].label;
                }
            }
        }
    }
    return 'categorie';
}

export function formatDate(x, y) {
    x = new Date(x);
    const z = {
        M: x.getMonth() + 1,
        d: x.getDate(),
        h: x.getHours(),
        m: x.getMinutes(),
        s: x.getSeconds(),
    };
    y = y.replace(/(M+|d+|h+|m+|s+)/g, function (v) {
        return ((v.length > 1 ? '0' : '') + eval('z.' + v.slice(-1))).slice(-2);
    });

    return y.replace(/(y+)/g, function (v) {
        return x.getFullYear().toString().slice(-v.length);
    });
}

export function slugify(text) {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-')
        .replace(/[^\w\-]+/g, '')
        .replace(/\-\-+/g, '-')
        .replace(/^-+/, '')
        .replace(/-+$/, '');
}

// export function slugifyPage(text) {
//     return text.toString().toLowerCase()
//         .replace(/\s+/g, '-')
//         .replace(/[^\w\-]+/g, '')
//         .replace(/\-\-+/g, '-')
//         .replace(/^-+/, '')
//         .replace(/-+$/, '');
// }

export function _base64ToArrayBuffer(base64) {
    const bString = window.atob(base64);
    const len = bString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = bString.charCodeAt(i);
    }
    return bytes.buffer;
}

export function filter(text, length, clamp = '...') {
    const node = document.createElement('div');
    node.innerHTML = text;
    const content = node.textContent;
    return content.length > length ? content.slice(0, length) + clamp : content;
}

export function debugPromise(func = 'unknown', err) {
    if (process.env.NODE_ENV === 'development') {
        console.error(func, err);
    } else {
        console.warn('error', btoa(func));
    }
}

export function windowForm(inputs) {
    const f = document.createElement('form');
    f.action = inputs['action'];
    f.method = 'POST';
    f.setAttribute('target', '_blank');

    for (const i in inputs['inputs']) {
        window['input' + i] = document.createElement('input');
        window['input' + i].type = 'hidden';
        window['input' + i].name = i;
        window['input' + i].value = inputs['inputs'][i];
        f.appendChild(window['input' + i]);
    }
    document.body.appendChild(f);
    f.submit();
}

export function decodeHTML(string) {
    const parser = new DOMParser();
    const dom = parser.parseFromString(string, 'text/html');

    return dom.body.textContent;
}

export function b64DecodeUnicode(str) {
    return decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
}

export function recursiveFind(array, key, value, childrenKey) {
    let o;
    array.some(function iter(a) {
        if (a[key] === value) {
            o = a;
            return true;
        }
        return Array.isArray(a[childrenKey]) && a[childrenKey].some(iter);
    });
    return o;
}

export function createBlob(type, data) {
    const contentType = type;
    const sliceSize = 512;
    const b64Data = data;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {
        type: contentType,
    });
    const blobUrl = URL.createObjectURL(blob);
    return blobUrl;
}

export function validateUrl(url) {
    const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    const pattern = new RegExp(regex);
    return pattern.test(url);
}

export function calculateYearsBetween(startDate) {
    const startYear = startDate;
    // The start year

    const endYear = new Date().getYear() + 1900;
    // The end year

    const output = []; // Initialize the output

    // For each year between push that year into the output
    for (let year = startYear; year <= endYear; year++) output.push(String(year));

    // Return the output
    return output;
}

export function get2D(num) {
    return (num.toString().length < 2 ? '0' + num : num).toString();
}

export function currentDate() {
    return new Date().toJSON().slice(0, 10).replace(/-/g, '-');
}

export function lastMonthDate() {
    const x = new Date();
    x.setDate(x.getDate() - 30);
    return x.toISOString().split('T')[0];
}

export function nFormatter(num, digits) {
    const si = [
        {
            value: 1,
            symbol: '',
        },
        {
            value: 1e3,
            symbol: 'k',
        },
        {
            value: 1e6,
            symbol: 'M',
        },
        {
            value: 1e9,
            symbol: 'B',
        },
        {
            value: 1e12,
            symbol: 'T',
        },
        {
            value: 1e15,
            symbol: 'Q',
        },
        {
            value: 1e18,
            symbol: 'QQ',
        },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
}

export function base64toBlob(base64Data, contentType) {
    contentType = contentType || '';
    const sliceSize = 1024;
    const byteCharacters = atob(base64Data);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
        const begin = sliceIndex * sliceSize;
        const end = Math.min(begin + sliceSize, bytesLength);

        const bytes = new Array(end - begin);
        for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
            bytes[i] = byteCharacters[offset].charCodeAt(0);
        }
        byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, {
        type: contentType,
    });
}

export function generateUID() {
    return '_' + Math.random().toString(36).substr(2, 9);
}

export function fmod(dividend, divisor) {
    let multiplier = 0;
    while (divisor * multiplier < dividend) {
        ++multiplier;
    }
    --multiplier;
    return dividend - divisor * multiplier;
}

export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function checkCIF(s) {
    if (parseInt(s) != s) {
        if (s.substring(0, 2).toUpperCase() != 'RO' || s.length > 12) {
            return false;
        }
        s = s.substring(2, s.length);
    } else {
        if (s.length > 10) {
            return false;
        }
    }
    const cifraControl = s.charAt(s.length - 1);
    let content = s.substring(0, s.length - 1);
    while (content.length < 9) {
        content = '0' + content;
    }
    let suma =
        content.charAt(0) * 7 +
        content.charAt(1) * 5 +
        content.charAt(2) * 3 +
        content.charAt(3) * 2 +
        content.charAt(4) * 1 +
        content.charAt(5) * 7 +
        content.charAt(6) * 5 +
        content.charAt(7) * 3 +
        content.charAt(8) * 2;
    suma = suma * 10;
    let rest = suma % 11;
    if (rest == 10) {
        rest = 0;
    }
    if (rest == cifraControl) {
        return true;
    } else {
        return false;
    }
}

export function checkIBAN(input) {
    const CODE_LENGTHS = {
        AD: 24,
        AE: 23,
        AT: 20,
        AZ: 28,
        BA: 20,
        BE: 16,
        BG: 22,
        BH: 22,
        BR: 29,
        CH: 21,
        CR: 21,
        CY: 28,
        CZ: 24,
        DE: 22,
        DK: 18,
        DO: 28,
        EE: 20,
        ES: 24,
        FI: 18,
        FO: 18,
        FR: 27,
        GB: 22,
        GI: 23,
        GL: 18,
        GR: 27,
        GT: 28,
        HR: 21,
        HU: 28,
        IE: 22,
        IL: 23,
        IS: 26,
        IT: 27,
        JO: 30,
        KW: 30,
        KZ: 20,
        LB: 28,
        LI: 21,
        LT: 20,
        LU: 20,
        LV: 21,
        MC: 27,
        MD: 24,
        ME: 22,
        MK: 19,
        MR: 27,
        MT: 31,
        MU: 30,
        NL: 18,
        NO: 15,
        PK: 24,
        PL: 28,
        PS: 29,
        PT: 25,
        QA: 29,
        RO: 24,
        RS: 22,
        SA: 24,
        SE: 24,
        SI: 19,
        SK: 24,
        SM: 27,
        TN: 24,
        TR: 26,
    };
    const iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, ''); // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/); // match and capture (1) the country code, (2) the check digits, and (3) the rest

    // check syntax and length
    if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
        return false;
    }
    // rearrange country code and check digits, and convert chars to ints
    const digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, function (letter) {
        return letter.charCodeAt(0) - 55;
    });
    // final check
    return mod97(digits);
}

export function mod97(string) {
    let checksum = string.slice(0, 2);
    let fragment;
    for (let offset = 2; offset < string.length; offset += 7) {
        fragment = String(checksum) + string.substring(offset, offset + 7);
        checksum = parseInt(fragment, 10) % 97;
    }
    return checksum;
}

export function htmlDecode(input) {
    const doc = new DOMParser().parseFromString(input, 'text/html');
    return doc.documentElement.textContent || '';
}

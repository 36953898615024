import Vue from 'vue';

import {
    Autocomplete,
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    Button,
    ButtonGroup,
    Table,
    TableColumn,
    DatePicker,
    TimeSelect,
    Popover,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Alert,
    Row,
    Col,
    Upload,
    Progress,
    Badge,
    Card,
    Rate,
    Steps,
    Step,
    Collapse,
    CollapseItem,
    Cascader,
    Transfer,
    Link,
    Divider,
    Image,
    Loading,
    MessageBox,
    Pagination,
    Notification,
    Scrollbar,
} from 'element-ui';

import 'normalize.css/normalize.css'; // A modern alternative to CSS resets
import '@/styles/fonts.css';
import '@/styles/icons.css';
import '@/styles/index.scss'; // global css
// import 'babel-polyfill'
// import 'highlight.js/styles/atom-one-light.css';
import 'semantic-ui-flag/flag.min.css';
import 'vue-tel-input/dist/vue-tel-input.css';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueGtag from 'vue-gtag';

import App from './App';
import router from './router';
import store from './store';

import i18n from './lang'; // Internationalization

import '@/permission';

import { recursiveFind } from '@/utils/index';

import SvgIcon from '@/components/SvgIcon';

import { mapActions } from 'vuex';

import logger from '@/utils/logger';

// For HTTPS
Vue.prototype.$https = false;
Vue.prototype.$env = process.env.NODE_ENV === 'production' ? 'production' : 'development';

if (window.location.hostname.replace(/\.+$/, '') === 'demo.cel.ro') {
    window.location.href = 'https://marketplace.cel.ro';
}

if (Vue.prototype.$https && location.protocol === ':http') {
    location.href = location.href.replace('http://', 'https://');
} else if (!Vue.prototype.$https && location.protocol === ':https') {
    location.href = location.href.replace('https://', 'http://');
}
Sentry.init({
    dsn: process.env.SENTRY_DNS,
    integrations: [
        new Integrations.Vue({
            Vue,
            attachProps: true,
            logErrors: Vue.prototype.$env !== 'production',
        }),
    ],
    beforeSend(event, hint) {
        const error = hint.originalException;
        /**
         * Oprim eventul daca nu avem exceptie
         */
        if (
            error.reqid ||
            error.canceled ||
            error.cancel ||
            error.error ||
            error.name === 'LocalError' ||
            error.message === 'connection already closed'
        )
            return null;

        const user = {};
        if (store.state.user != null && store.state.user.info != null && store.state.user.info.email != null) {
            user.email = store.state.user.info.email;
        }
        if (store.state.user != null && store.state.user.info != null && store.state.user.info.name != null) {
            user.name = store.state.user.info.name;
        }

        // let translation;
        // if (store.getters['app/currentLang'] === 'ro') {
        // 	var translation = {
        // 		title: 'Se pare ca ai gasit o eroare.',
        // 		subtitle: 'Departamentul tehnic a fost deja notificat.',
        // 		subtitle2: 'Daca vrei sa ne ajuti cu mai multe detalii, completeaza formularul de mai jos.',
        // 		labelName: 'Nume',
        // 		labelEmail: 'Email',
        // 		labelComments: 'Ce s-a intamplat?',
        // 		labelClose: 'Inchide',
        // 		labelSubmit: 'Trimite',
        // 		errorGeneric: 'A intervenit o eroare. Incearca din nou.',
        // 		errorFormEntry: 'Unele campuri sunt invalide.',
        // 		successMessage: 'Formularul a fost trimis. Multumim!'
        // 	};
        // }
        /**
         * 19.12.2019 AlinT
         * Dezactivat momentan pentru ca avem multe erori false
         */
        // Sentry.showReportDialog({
        // 	eventId: event.event_id,
        // 	user,
        // 	...translation
        // })

        event.extra = event.extra || {};
        event.extra = {
            ...event.extra,
            version: store.getters['app/appVersion'] || 1,
            appState: store.state.app,
            routeState: store.state.route,
            userState: store.state.user,
        };
        return event;
    },
    environment: Vue.prototype.$env,
    ignoreErrors: [
        'ResizeObserver loop limit exceeded',
        'ResizeObserver loop completed with undelivered notifications',
    ],
});
Vue.prototype.$reportToSentry = (error, context = {}) =>
    Sentry.withScope((scope) => {
        /**
         * 20.12.19 AlinT
         * In traducere:
         * Daca eroarea este setata, mesajul la eroare este setat, mesajul nu este de tip "Departamentul tehnic a fost notificat",
         * constructorul nu exista sau constructorul exista SI numele constructorului nu este de tip ReferenceError, TypeError, etc.
         */
        if (
            !error ||
            !error.message ||
            !(Object.prototype.toString.call(error.message) === '[object String]') ||
            !error.constructor ||
            (error.constructor && !/Error$/.test(error.constructor.name)) ||
            error.canceled === 1
        )
            return false;

        const extra = {
            ...context.extra,
            version: store.getters['app/appVersion'],
            appState: store.state.app,
            routeState: store.state.route,
            userState: store.state.user,
        };
        if (error && (!error.stack || error.reqid || error.error)) {
            // Generam prea multe erori false
            extra.error = error;
            error = error.reqid
                ? new Error(
                      Object.prototype.toString.call(error.message) === '[object String]'
                          ? error.message
                          : 'Response error'
                  )
                : error;
        }
        if (context.tags != null) {
            scope.setTags({
                ...context.tags,
            });
        }

        scope.setExtras(extra);
        Sentry.captureException(error);
    });

Vue.component('svg-icon', SvgIcon);

// import Chat from 'vue-beautiful-chat';
// import Chat from '../../marketplace_chat/dist/index'
// Vue.use(Chat, {});

/**
 * 17.12.2019 AlinT
 * Pentru situatiile in care facem deploy la versiune noua si clientul e pe site
 */
router.onError((e) => {
    if (/loading chunk \d* failed./i.test(e.message) && window.location.hash !== '#retry') {
        MessageBox.alert(
            'O noua versiune a aplicatiei este disponibila. Pagina se va reincarca automat.',
            'Actualizare',
            {
                confirmButtonText: 'OK',
                callback: (a) => {
                    window.location.hash = '#retry';
                    window.location.reload(true);
                },
            }
        );
    }
});

Vue.mixin({
    methods: {
        getCurrentRoute() {
            const entryKey = this.$route.name;
            const route = recursiveFind(this.$router.options.routes, 'name', entryKey, 'children');
            if (typeof route === 'undefined' || router === '') return false;
            else return route;
        },
    },
});

Vue.mixin({
    methods: {
        ...mapActions({
            sbMsg: 'snackbar/addMessage',
        }),
    },
});

Vue.use(
    VueGtag,
    {
        config: { id: 'G-G43YTCDKNH' },
    },
    router
);

if (process.env.NODE_ENV === 'production') {
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true;
    Vue.config.productionTip = false;
}

Object.keys(logger).forEach((method) => {
    Vue.prototype[`$${method}`] = logger[method].bind(Vue);
});

const elementPlusComponents = [
    Autocomplete,
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    Menu,
    Submenu,
    MenuItem,
    MenuItemGroup,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxButton,
    CheckboxGroup,
    Switch,
    Select,
    Option,
    Button,
    ButtonGroup,
    Table,
    TableColumn,
    DatePicker,
    TimeSelect,
    Popover,
    Tooltip,
    Breadcrumb,
    BreadcrumbItem,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Tag,
    Alert,
    Row,
    Col,
    Upload,
    Progress,
    Badge,
    Card,
    Rate,
    Steps,
    Step,
    Collapse,
    CollapseItem,
    Cascader,
    Transfer,
    Link,
    Divider,
    Image,
    Loading,
    Scrollbar,
    Pagination,
    Loading.directive,
];

Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
Vue.prototype.$notify = Notification;

/**
 * Element Plus On demand components registration
 */
for (const comp of elementPlusComponents) {
    Vue.use(comp);
}

new Vue({
    el: '#app',
    router,
    store,
    i18n,
    render: (h) => h(App),
    mounted: () => document.dispatchEvent(new Event('x-app-rendered')),
    // components: {
    //     App
    // }
});

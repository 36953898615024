import Vue from 'vue';

import router from './router';
import { getToken } from '@/utils/auth';
import store from '@/store';
import { generateTitle } from '@/utils/i18n';
import i18n from '@/lang';
import trackHit from '@/utils/hitcounter';
const redirect = '/dashboard/index';
router.beforeEach(async (to, from, next) => {
    try {
        const nearestWithTitle = to.matched
            .slice()
            .reverse()
            .find((r) => r.meta && r.meta.title);
        if (nearestWithTitle) document.title = generateTitle.call(i18n, nearestWithTitle.meta.title, true);
        await store.dispatch('app/RemovePendingReqs');
        if (!getToken() && to.matched.some((record) => record.meta.noAuth)) {
            return next();
        }

        if (
            to.matched.some((record) => record.path.includes('/login')) &&
            to.query.username_token &&
            to.query.password_token
        ) {
            await store.dispatch('user/LogOut', {
                withRequest: false
            });
            return next();
        }

        await store.dispatch('user/SetupFingerPrint');
        if (to.query == null || Object.keys(to.query).length < 1 || !store.getters['user/userHasInfoLoaded']) {
            await store.dispatch('user/GetAffiliateData');
        }

        if (to.matched.some((record) => record.meta.noAuth || record.meta.authRedirect)) {
            return next({
                path: redirect
            });
        }

        return next();
    } catch (e) {
        Vue.prototype.$reportToSentry(e, {
            extra: {
                fn: 'permissionsBefore'
            }
        });
        try {
            await store.dispatch('user/LogOut', {
                withRequest: false
            });
        } catch (e) {
            Vue.prototype.$reportToSentry(e, {
                extra: {
                    fn: 'permissionsBeforeLogout'
                }
            });
        } finally {
            return next({
                path: '/login'
            });
        }
    }
});

router.afterEach((to, from) => {
    if (getToken() && !/^\/register\/\S+$/.test(to.path)) {
        // Tracking paths
        trackHit(to).catch((err) => console.log(err));
        Promise.resolve()
            .then(() => {
                return store.dispatch('user/GetAccountRestrictions');
            })
            .then((restrictions) => {
                if (
                    Array.isArray(restrictions) &&
                    restrictions.length > 0 &&
                    to.meta != null &&
                    to.meta.module != null
                ) {
                    const module = to.meta.module.split('.');
                    const baseModule = module[0];
                    const BreakException = {};
                    try {
                        restrictions.some((restriction) => {
                            restriction.impact.some((impact) => {
                                if (
                                    impact.name === to.meta.module ||
                                    (!/\./.test(impact.name) && baseModule === impact.name)
                                ) {
                                    store.dispatch('snackbar/addMessage', {
                                        type: 'error',
                                        message: `Modulul <strong>${impact.default_label}</strong> a fost restrictionat pe contul dumneavoastra.`
                                    });
                                    router
                                        .push({
                                            path: redirect
                                        })
                                        .catch(() => {});
                                    throw BreakException;
                                }
                            });
                        });
                    } catch (e) {
                        if (e !== BreakException) {
                            console.log(e);
                            throw e;
                        }
                    }
                }
            })
            .catch(async (e) => {
                Vue.prototype.$reportToSentry(e, {
                    extra: {
                        fn: 'permissionsAfter'
                    }
                });
                try {
                    await store.dispatch('user/LogOut', {
                        withRequest: false
                    });
                } catch (e) {
                    Vue.prototype.$reportToSentry(e, {
                        extra: {
                            fn: 'permissionsAfterLogout'
                        }
                    });
                }
            });
    }
});

const EColetCallbackPage = () => import('@/views/cont/ecolet/callback');
export default [
    {
        path: '/callback/ecolet',
        name: 'Ecolet Callback',
        component: EColetCallbackPage,
        meta: {
            tour: false,
            demoRestricted: false,
            icon: 'truck',
            module: 'account.couriers',
            hidden: true,
        },
    },
];

import fetch from '@/utils/fetch';

// export function countProductEmails() {
// 	return fetch({
// 		url: '/email/countProductEmails',
// 		method: 'post'
// 	})
// }

// export function countOrdersFeedback() {
// 	return fetch({
// 		url: '/orders/countOrdersFeedback',
// 		method: 'post'
// 	})
// }

export function getBadges() {
	return fetch({
		url: '/front/getBadges',
		method: 'post'
	})
}

export function getLanguages() {
	return fetch({
		url: '/front/getLanguages',
		method: 'post'
	})
}

export function setLanguage(data) {
	return fetch({
		url: '/front/setLanguage',
		method: 'post',
		data
	})
}
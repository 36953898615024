export const initialState = () => ({
	list: [],
	timeout: 3000
});

const getters = {
	list: (state) => state.list,
};

const mutations = {
	'ADD_MESSAGE': (state, message) => state.list.unshift(message),
	'REMOVE_MESSAGE': (state, id) => {
		state.list = state.list.filter((m) => m.id !== id);
	}
};

const actions = {
	addMessage: ({
		commit
	}, message) => {
		message = typeof message === 'string' ? {
			message,
			type: 'info',
			timeout: 3000
		} : message;
		const obj = {
			type: message.type || 'info',
			message: message.message,
			timeout: message.timeout || Math.max(2000, 2000 * message.message.length / 25),
			time: (new Date).getTime(),
			id: Math.random().toString(36).substr(2, 9)
		};
		commit('ADD_MESSAGE', obj);
	},
};

export function createSnackbarStore() {
	return {
		namespaced: true,
		state: initialState,
		actions,
		getters,
		mutations,
	};
}
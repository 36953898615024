import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang
import elementRoLocal from 'element-ui/lib/locale/lang/ro'; // element-ui lang
import enLocale from './en.json';
import roLocale from './ro.json';
import ElementLocale from 'element-ui/lib/locale';

Vue.use(VueI18n);

const messages = {
    en: {
        ...enLocale,
        ...elementEnLocale
    },
    ro: {
        ...roLocale,
        ...elementRoLocal
    }
};

const i18n = new VueI18n({
    // set locale
    // options: en or ro
    locale: Cookies.get('lang') || 'ro',
    // set locale messages
    messages
});

ElementLocale.i18n((key, value) => i18n.t(key, value));

export default i18n;

/* eslint-disable prefer-promise-reject-errors */
import Fingerprint2 from 'fingerprintjs2';
import Cookies from 'js-cookie';
import { login, getInfo, logout, getAccountRestrictions, recheckRestriction } from '@/api/login';
import { getAffiliateData, approveGDPR, approveTOS, getRestrictionEntities } from '@/api/cont';
import { setToken, removeToken } from '@/utils/auth';
import avatarImage from '@/assets/images/avatar.svg';
import jsCookie from 'js-cookie';

export default {
    Login({ commit }, userInfo) {
        const username = userInfo.username.trim();

        
        return new Promise((resolve, reject) => {
            login(username, userInfo.password, +userInfo.remember)
                .then((data) => {
                    setToken(data.message);
                    commit('SET_TOKEN', data.message);
                    commit('CHECK_REMEMBER_ME', userInfo.remember);
                    resolve();
                })
                .catch((error) => {
                    console.log('Error LOGIN: ' + error.message);
                    reject(error);
                });
        });
    },

    GetInfo({ commit, state }) {
        return new Promise((resolve, reject) => {
            getInfo(state.token)
                .then((data) => {
                    commit('SET_ROLES', data.message.role);
                    commit('SET_NAME', data.message.name);
                    commit('SET_AVATAR', avatarImage);
                    resolve(data);
                })
                .catch((error) => {
                    console.log('Error GETINFO: ' + error);
                    reject(error);
                });
            // commit('SET_ROLES', ['admin'])
            // commit('SET_NAME', 'Afiliat')
            // commit('SET_AVATAR', avatarImage)
            // resolve()
        });
    },

    LogOut({ commit, state }, options) {
        return new Promise(async (resolve, reject) => {
            try {
                if (options != null && options.withRequest) {
                    await logout();
                }
            } catch (err) {
                // TODO:  SENTRY BOSSS
            } finally {
                commit('SET_TOKEN', '');
                commit('SET_ROLES', []);
                removeToken();
                commit('app/RESET_APP_STATE', null, {
                    root: true,
                });
                commit('user/RESET_STATE', null, {
                    root: true,
                });
                commit('dashboard/RESET_STATE', null, {
                    root: true,
                });
                commit('feeds/RESET_STATE', null, {
                    root: true,
                });
                commit('chat/RESET_STATE', null, {
                    root: true,
                });
                return resolve();
            }
        });
    },
    FedLogOut({ commit }) {
        return new Promise((resolve) => {
            commit('SET_TOKEN', '');
            removeToken();
            resolve();
        });
    },
    SetUserInfo: ({ commit }, data) => {
        commit('SET_USER_INFO', data);
    },
    GetAccountRestrictions: ({ commit, state }) => {
        return new Promise((resolve, reject) => {
            if (state.gotRestrictions) {
                resolve(state.restrictions);
            } else {
                getAccountRestrictions()
                    .then((res) => {
                        if (res == null || res.message == null)
                            return reject(new Error('Nu am putut verifica datele contului.'));
                        commit('SET_USER_RESTRICTIONS', res.message);
                        resolve(res.message);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            }
        });
    },
    RecheckRestriction: ({ commit, state, dispatch }, restriction) => {
        return new Promise((resolve, reject) => {
            recheckRestriction({
                restriction,
            })
                .then((res) => {
                    commit('SET_USER_RESTRICTIONS', res.message);
                    resolve(res.message);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    SetAffiliateFeedback: ({ commit }, data) => {
        const cFeedback = jsCookie.get('affiliate-feedback');
        if (cFeedback == null && parseInt(data.wantFeedback) == 1) {
            commit('SET_AFFILIATE_FEEDBACK', 1);
        }
    },
    CancelFeedback: ({ commit }) => {
        jsCookie.set('affiliate-feedback', 1, {
            expires: 5,
        });
        commit('SET_AFFILIATE_FEEDBACK', 0);
    },
    ConfirmFeedback: ({ commit }, route) => {
        jsCookie.set('affiliate-feedback', 1, {
            expires: 5,
        });
        commit('SET_AFFILIATE_FEEDBACK', 0);

        route.push('/cont/feedback-afiliati');
    },
    GetAffiliateData: ({ commit, dispatch, state }) => {
        return new Promise((resolve, reject) => {
            getAffiliateData({
                fingerprint: state.fp,
            })
                .then(async (res) => {
                    if (typeof res.message !== 'undefined') {
                        await dispatch('SetUserInfo', res.message);
                        dispatch('user/SetAffiliateFeedback', res.message, {
                            root: true,
                        });
                        dispatch('notifications/InitNotificationsBar', null, {
                            root: true,
                        });
                        commit('SET_INFO_LOADED');
                    } else {
                        return reject(new Error('No user data'));
                    }
                    resolve();
                })
                .catch((e) => {
                    reject(e);
                });
        });
    },
    SetupFingerPrint: ({ commit }) => {
        return new Promise((resolve, reject) => {
            try {
                const cookieFp = Cookies.get('mpfp2');
                if (cookieFp) {
                    commit('SET_FINGERPRINT', cookieFp);
                    return resolve(1);
                }
                Fingerprint2.get((components) => {
                    const values = components.map((component) => {
                        return component.value;
                    });
                    commit('SET_FP_COMPONENTS', components);
                    const hash = Fingerprint2.x64hash128(values.join(''), 31);
                    if (hash != null) {
                        commit('SET_FINGERPRINT', hash);
                        Cookies.set('mpfp2', hash, {
                            expires: 5,
                        });
                    }
                    return resolve(1);
                });
            } catch (err) {
                // Aici nu mai este nevoie sa dam reject pentru ca nu este important fp
                return resolve(0);
            }
        });
    },
    ApproveGDPR: ({ commit }) => {
        return approveGDPR().then((res) => {
            commit('SET_GDPR_STATUS', 1);
        });
    },
    ApproveTOS: ({ commit }) => {
        return approveTOS().then((res) => {
            commit('SET_TOS_STATUS', 1);
        });
    },
    GetRestrictionEntities({ commit }, restrictionId) {
        return getRestrictionEntities({ restrictionId });
    },
};

const Layout = () => import('@/views/layout/Layout');
const FacturiPage = () => import('@/views/financiar/facturi');
const CompensariPage = () => import('@/views/financiar/compensari');
const IstoricIncasariPage = () => import('@/views/financiar/istoric-incasari');
const IstoricPlatiPage = () => import('@/views/financiar/istoric-plati');
const PlatiCardPage = () => import('@/views/financiar/plati-card');
const PlatiPage = () => import('@/views/financiar/plati');

export default [
    {
        path: '/financiar',
        component: Layout,
        redirect: '/financiar/facturi',
        name: 'Facturi',
        meta: {
            tour: false,
            icon: 'money',
            order: 2,
            title: 'payments-main',
            hidden: false,
            badge: ['unpaidInvoices'],
            demoRestricted: false,
            module: 'payments',
        },
        children: [
            {
                path: 'facturi',
                name: 'FacturiIndex',
                component: FacturiPage,
                meta: {
                    tour: true,
                    title: 'payments-billed',
                    icon: 'file-text',
                    badge: 'unpaidInvoices',
                    demoRestricted: false,
                    module: 'payments.payments-billed',
                },
                props: (route) => ({
                    filterQuery: {
                        start: route.query.start,
                        intervalStart: route.query.intervalStart,
                        intervalEnd: route.query.intervalEnd,
                        paidStatus: route.query.paidStatus,
                        invoiceType: route.query.invoiceType,
                        invoiceNumber: route.query.invoiceNumber,
                    },
                }),
            },
            // Scos temporar pana stabilizam modulul de compensari pe intervale
            // {
            // 	path: 'com-nefacturate',
            // 	name: 'Nefacturate',
            // 	component: _import('financiar/com-nefacturate'),
            // 	meta: {
            // 		tour: false,
            // 		title: 'payments-not-billed',
            // 		icon: 'tag-stroke',
            // 		demoRestricted: false,
            // 		module: 'payments.payments-notbilled'
            // 	}
            // },
            {
                path: 'compensari',
                name: 'Compensari',
                component: CompensariPage,
                meta: {
                    tour: false,
                    title: 'payments-compensations',
                    icon: 'cart',
                    demoRestricted: true,
                    module: 'payments.payments-compensations',
                },
            },
            // {
            //     path: 'istoric-incasari', // ce am financiart afiliatului
            //     name: 'Istoric incasari',
            //     component: IstoricIncasariPage,
            //     meta: {
            //         tour: false,
            //         title: 'payments-receipts',
            //         icon: 'history-clock-button',
            //         demoRestricted: false,
            //         module: 'payments.payments-receipts',
            //     },
            //     props: (route) => ({
            //         filterQuery: {
            //             from: route.query.from,
            //             to: route.query.to,
            //         },
            //     }),
            // },
            // {
            //     path: 'istoric-plati', // ce am primit de la afiliat
            //     name: 'Istoric plati',
            //     component: IstoricPlatiPage,
            //     meta: {
            //         tour: false,
            //         title: 'payments-paid',
            //         icon: 'history-clock-button-2',
            //         demoRestricted: false,
            //         module: 'payments.payments-paid',
            //     },
            //     props: (route) => ({
            //         filterQuery: {
            //             from: route.query.from,
            //             to: route.query.to,
            //             month: route.query.month,
            //         },
            //     }),
            // },
            {
                path: 'plati-card',
                name: 'FinanciarPlaticucardul',
                component: PlatiCardPage,
                meta: {
                    tour: false,
                    title: 'payments-card',
                    icon: 'credit-card',
                    demoRestricted: false,
                    module: 'payments.payments-card-page',
                },
            },
            {
                path: 'plati',
                name: 'FinanciarPlati',
                component: PlatiPage,
                meta: {
                    tour: false,
                    title: 'payments',
                    icon: 'coins',
                    demoRestricted: false,
                    module: 'payments.payments-page',
                },
            },
        ],
    },
];

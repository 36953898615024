import { setRememberMe, removeRememberMe } from '@/utils/auth';

import { initialState } from './index';

export default {
    SET_TOKEN: (state, token) => {
        state.token = token;
    },
    SET_NAME: (state, name) => {
        state.name = name;
    },
    SET_AVATAR: (state, avatar) => {
        state.avatar = avatar;
    },
    SET_ROLES: (state, roles) => {
        state.roles = roles;
    },
    RESET_STATE: (state) => {
        const iState = initialState();
        for (const prop in state) {
            if (typeof iState[prop] !== 'undefined' && prop !== 'token') {
                state[prop] = iState[prop];
            }
        }
    },
    CHECK_REMEMBER_ME: (state, remember) => {
        if (typeof remember === 'undefined' || !remember) {
            removeRememberMe();
            state.remember = 0;
        } else {
            setRememberMe();
            state.remember = 1;
        }
    },
    SET_USER_INFO: (state, data) => {
        state.info = data;
    },
    SET_AFFILIATE_FEEDBACK: (state, data) => {
        state.feedback = data;
    },
    SET_USER_RESTRICTIONS: (state, restrictions) => {
        state.gotRestrictions = true;
        state.restrictions = restrictions;
    },
    SET_AFFILIATE_FEEDBACK: (state, data) => {
        state.feedback = data;
    },
    SET_USER_LINK: (state, link) => {
        state.info.siteUrl = link;
    },
    SET_USER_HAS_CODE: (state, status) => {
        state.info.hasCodeOnSite = status;
    },
    SET_FINGERPRINT: (state, fp) => {
        state.fp = fp;
    },
    SET_FP_COMPONENTS: (state, components) => {
        state.fpComponents = components;
    },
    SET_INFO_LOADED: (state) => (state.infoLoaded = true),
    SET_LANGUAGE_ID: (state, data) => {
        state.info.language_id = data;
    },
    SET_GDPR_STATUS: (state, val) => (state.info.gdpr = val),
    SET_TOS_STATUS: (state, val) => (state.info.tos = val)
};

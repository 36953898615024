import {
	getToken,
	getRememberMe
} from '@/utils/auth';

import actions from './actions';
import gettersList from './getters';
import mutationsList from './mutations';
import {
	make
} from 'vuex-pathify';

export const initialState = () => ({
	token: getToken(),
	name: '',
	avatar: '',
	roles: [],
	info: {},
	restrictions: [],
	gotRestrictions: false,
	remember: getRememberMe(),
	feedback: 0,
	fp: null,
	fpComponents: {},
	infoLoaded: false
});

const mutations = {
	...make.mutations(initialState()),
	...mutationsList
};

const getters = {
	...make.getters(initialState()),
	...gettersList
};


export function createUserStore() {
	return {
		namespaced: true,
		state: initialState,
		actions,
		getters,
		mutations,
	};
}
const Layout = () => import('@/views/layout/Layout');
const ExtensiePage = () => import('@/views/integrari/extensie');
const ExporterPage = () => import('@/views/integrari/exporter');
const ExporterMagazinPage = () => import('@/views/integrari/magazin/index');
const ExporterShopPage = () => import('@/views/integrari/magazin/shop');
export default [
    {
        path: '/integrari',
        component: Layout,
        redirect: '/integrari/shop',
        name: 'Integrari',
        meta: {
            icon: 'tubiao',
            tour: false,
            order: 8,
            title: 'integrations',
            demoRestricted: true,
            module: 'integrations',
        },
        children: [
            // {
            //     path: 'extensie',
            //     name: 'Extensie Browser',
            //     component: ExtensiePage,
            //     meta: {
            //         icon: 'power-cord',
            //         tour: false,
            //         title: 'extension',
            //         demoRestricted: true,
            //         module: 'integrations.integrations-extension'
            //     },
            //     props: (route) => ({
            //         filterQuery: {}
            //     })
            // },
            // {
            //     path: 'exporter',
            //     name: 'exporter',
            //     component: ExporterPage,
            //     meta: {
            //         icon: 'file-text',
            //         tour: false,
            //         title: 'integrations-exporter',
            //         demoRestricted: true,
            //         module: 'integrations.integrations-exporter'
            //     },
            //     props: (route) => ({
            //         filterQuery: {}
            //     })
            // },
            {
                path: 'shop',
                name: 'shop',
                component: ExporterMagazinPage,
                meta: {
                    icon: 'power-cord',
                    tour: false,
                    title: 'shop',
                    demoRestricted: true,
                    module: 'integrations.integrations-shop',
                    hidden: false,
                },
                props: (route) => ({
                    filterQuery: {},
                }),
            },
            {
                path: 'shop/:id',
                meta: {
                    tour: false,
                    hidden: true,
                },
                props: true,
                name: 'shopView',
                hidden: true,
                component: ExporterShopPage,
                module: 'integrations.integrations-shop',
            },
        ],
    },
];

import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const initialState = () => ({
	enabled: true,
	opened: false,
	loading: false,
	list: [],
	start: 10,
	finishedList: false,
	bar: false,
	permission: false,
	cmVersion: 1,
	alert: false
});

export function createNotificationsStore() {
	return {
		namespaced: true,
		state: initialState,
		actions,
		getters,
		mutations,
	};
}
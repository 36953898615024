import {
	setCloudMessagingToken,
	getLatestNotifications,
	markNotificationListAsSeen,
	getNotificationsList
} from '@/api/notif';
import Cookies from 'js-cookie';
import {
	Notification
} from 'element-ui';

export default {
	ToggleNotifications: ({
		commit,
		state
	}) => {
		if (state.alert) {
			commit('SET_NOTIFICAITON_ALERT', false);
		}
		commit('TOGGLE_NOTIFICATIONS');
	},
	GetLatestNotifications: ({
		commit
	}) => {
		return new Promise((resolve, reject) => {
			commit('SET_LOADING', true);
			getLatestNotifications().then((result) => {
				if (result == null || result.message == null) return resolve();
				commit('SET_NOTIFICATIONS', result.message);
				commit('SET_LOADING', false);
				commit('SET_LIST_START', 10);
				commit('SET_FINISHED_LIST', false);
				return resolve();
			}).catch((err) => {
				commit('SET_LOADING', true);
				reject(err);
			});
		});
	},
	MarkCurrentListAsSeen: ({
		commit,
		state
	}) => {
		return new Promise((resolve, reject) => {
			let ids = state.list.filter((n) => n.seen == 0);
			if (ids.length < 1) return resolve();
			ids = ids.map((n) => n.id);
			markNotificationListAsSeen({
				ids
			}).then((res) => {
				commit('MARK_IDS_AS_READ', ids);
				return resolve();
			}).catch((err) => reject(err));
		});
	},
	MarkNotificationAsSeen: ({
		commit,
		state
	}, id) => {
		return new Promise((resolve, reject) => {
			markNotificationListAsSeen({
				ids: [id]
			}).then((res) => {
				commit('MARK_IDS_AS_READ', [id]);
				return resolve();
			}).catch((err) => reject(err));
		});
	},
	GetNotificationsList: ({
		commit,
		state
	}) => {
		return new Promise((resolve, reject) => {
			commit('SET_LOADING', true);
			getNotificationsList({
				start: state.start
			}).then((result) => {
				if (result == null || result.message == null) return resolve();
				commit('SET_LIST_START', result.message.start);
				commit('ADD_NOTIFICATIONS_TO_LIST', result.message.list);
				if (result.message.list.length < 1) commit('SET_FINISHED_LIST', true);
				commit('SET_LOADING', false);
				resolve();
			}).catch((err) => {
				commit('SET_LOADING', true);
				reject(err);
			});
		});
	},
	SendBrowserNotification: ({
		commit
	}, {
		title,
		message
	}) => {
		if (!Notification) {
			// alert('Browserul tau nu suporta notificari')
			return false;
		}
		if (Notification.permission !== 'granted') {
			return false;
		} else {
			const notification = new Notification(title, {
				body: message,
			});
		}
	},
	InitNotificationsBar: ({
		commit
	}) => {
		try {
			const barBlocked = Cookies.get('bblocked');
			const permissionStatus = window.Notification != null ? window.Notification.permission : null;
			if (permissionStatus == null || permissionStatus === 'blocked') {
				commit('SET_NOTIFICATIONS_PERMISSIONS', false);
				commit('SET_NOTIFICATIONS_BAR', false);
				return false;
			}

			if (permissionStatus === 'default' && barBlocked == null) {
				commit('SET_NOTIFICATIONS_PERMISSIONS', false);
				commit('SET_NOTIFICATIONS_BAR', true);
				return false;
			}

			if (permissionStatus === 'granted') {
				commit('SET_NOTIFICATIONS_PERMISSIONS', true);
				commit('SET_NOTIFICATIONS_BAR', false);
				return false;
			}
		} catch (err) {
			console.log(err);
		}
	},
	RemoveNotificationBar: ({
		commit
	}) => {
		Cookies.set('bblocked', 1, {
			expires: 7
		});
		commit('SET_NOTIFICATIONS_BAR', false);
	},
	ActivatePushNotifications: ({
		commit
	}) => {
		commit('SET_NOTIFICATIONS_BAR', false);
		commit('SET_NOTIFICATIONS_PERMISSIONS', true);
	},
	CheckCloudMessagingToken: async ({
		commit,
		dispatch,
		state,
		rootState
	}, token) => {
		try {
			const newToken = JSON.stringify({
				token,
				version: state.cmVersion,
				id: rootState.user.info.id
			});
			let savedToken = localStorage.getItem('cm-token');
			if (savedToken != null) {
				try {
					savedToken = JSON.parse(savedToken);
					if (savedToken.version != null && parseInt(savedToken.version) === state.cmVersion &&
						parseInt(savedToken.id) === parseInt(rootState.user.info.id) &&
						rootState.user.info.cmToken === token) {
						if (savedToken.token === token) {
							return true;
						} else {
							await dispatch('SetCloudMessagingToken', token);
							localStorage.setItem('cm-token', newToken);
							return true;
						}
					} else {
						await dispatch('SetCloudMessagingToken', token);
						localStorage.setItem('cm-token', newToken);
						return true;
					}
				} catch (err) {
					await dispatch('SetCloudMessagingToken', token);
					localStorage.setItem('cm-token', newToken);
					return true;
				}
			} else {
				await dispatch('SetCloudMessagingToken', token);
				localStorage.setItem('cm-token', newToken);
				return true;
			}
		} catch (err) {
			console.log(err);
			return false;
		}
	},
	SetCloudMessagingToken: ({
		commit,
		rootState
	}, token) => {
		return new Promise(async (resolve, reject) => {
			try {
				await setCloudMessagingToken({
					token,
					fingerprint: rootState.user.fp
				});
				resolve();
			} catch (err) {
				reject(err);
			}
			resolve();
		});
	},
	AlertUserForNotification: ({
		commit,
		state
	}, data) => {
		commit('PREPEND_NOTIFICATION', {
			date: data.data.date,
			href: data.notification.click_action,
			id: data.data.notificationId,
			message: data.notification.body,
			seen: 0,
			title: data.notification.title,
			type: data.data.type
		});
		if (!state.opened) {
			commit('SET_NOTIFICAITON_ALERT', true);
		}

		Notification({
			title: 'Notificare: ' + data.notification.title,
			message: data.notification.body,
			type: 'info',
			position: 'top-left',
			duration: 6 * 1000
		});
	}
};
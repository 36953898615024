const Layout = () => import('@/views/layout/Layout');
const ComenziPage = () => import('@/views/vanzari/comenzi');
const ComandaPage = () => import('@/views/vanzari/comanda');
const CupoanePage = () => import('@/views/vanzari/cupoane');
const CampaniePage = () => import('@/views/vanzari/campanie');
const SmartbillPage = () => import('@/views/cont/facturare-smartbill');
const Facturare = () => import('@/views/vanzari/facturare');

export default [
    {
        path: '/vanzari',
        component: Layout,
        redirect: '/vanzari/comenzi',
        name: 'Vanzari',
        meta: {
            icon: 'cart',
            tour: false,
            order: 3,
            title: 'sells-main',
            module: 'sales',
            badge: ['comenzi']
        },
        children: [
            {
                path: 'comenzi',
                name: 'Comenzi',
                component: ComenziPage,
                meta: {
                    icon: 'cart',
                    tour: true,
                    title: 'sells-orders',
                    demoRestricted: false,
                    module: 'sales.sales-orders',
                    yt: {
                        active: true,
                        link: 'https://www.youtube.com/embed/YMjN34k6MVg'
                    },
                    badge: ['comenzi']
                },
                props: (route) => ({
                    filterQuery: {
                        minDate: route.query.minDate,
                        maxDate: route.query.maxDate,
                        start: route.query.start,
                        customer: route.query.customer,
                        customerSign: route.query.customerSign,
                        product: route.query.product,
                        productSign: route.query.productSign,
                        order: route.query.order,
                        invoice: route.query.invoice,
                        order_status: route.query.order_status,
                        payment_method: route.query.payment_method
                    }
                })
            },
            {
                path: 'comanda/:cid',
                name: 'Comanda',
                props: true,
                component: ComandaPage,
                meta: {
                    tour: true,
                    title: 'sells-orders-single',
                    hidden: true,
                    demoRestricted: false,
                    yt: {
                        active: true,
                        link: 'https://www.youtube.com/embed/YMjN34k6MVg'
                    },
                    module: 'sales.sales-orders'
                }
            },
            {
                path: 'cupoane',
                name: 'Cupoane',
                component: CupoanePage,
                meta: {
                    icon: 'ticket',
                    tour: false,
                    title: 'sells-coupons',
                    demoRestricted: false,
                    module: 'sales.sales-coupons'
                }
            },
            {
                path: 'campanie/:cid',
                name: 'Campanie Cupoane',
                props: true,
                component: CampaniePage,
                meta: {
                    icon: 'zonghe',
                    tour: false,
                    title: 'sells-coupons-single',
                    hidden: true,
                    module: 'sales.sales-coupons'
                }
            },
            // {
            //     path: 'facturare-smartbill',
            //     name: 'Facturare Smartbill',
            //     component: SmartbillPage,
            //     meta: {
            //         tour: false,
            //         title: 'facturare-smartbill',
            //         icon: 'coins',
            //         yt: {
            //             active: true,
            //             link: 'https://www.youtube.com/embed/PD1IlMXa9p8'
            //         },
            //         demoRestricted: false,
            //         module: 'sales.sales-smartbill'
            //     }
            // },
            {
                path: 'facturare',
                name: 'Facturare',
                component: Facturare,
                meta: {
                    tour: false,
                    title: 'sells-billing',
                    icon: 'coins',
                    yt: {
                        active: true,
                        link: 'https://www.youtube.com/embed/PD1IlMXa9p8'
                    },
                    demoRestricted: false,
                    module: 'sales.sales-billing'
                }
            }
            // {
            //     path: 'facturare',
            //     name: 'Facturare produse',
            //     icon: 'zonghe',
            //     component: _import('vanzari/facturare')
            // }
        ]
    }
];

import Cookies from 'js-cookie';
import actions from './actions';
import gettersFile from './getters';
import mutationsFile from './mutations';
import { make } from 'vuex-pathify';

export const initialState = () => ({
    packageVersion: process.env.PACKAGE_VERSION || '0',
    sidebar: {
        opened: !+Cookies.get('sidebarStatus'),
        withoutAnimation: false
    },
    bodyLoading: false,
    deliveryCouriers: {
        0: 'Fan Courier',
        1: 'Nemo Express'
    },
    hasFocus: true,
    device: 'desktop',
    badges: {},
    features: Cookies.get('newFeatures'),
    featuresKey: 'features260618',
    lang: Cookies.get('lang') || 'ro',
    queryParams: {},
    feedbackActive: false,
    supplierCategories: [],
    alreadyCalling: false,
    requests: [],
    videoInstructions: {
        active: false,
        link: ''
    },
    trumbowyg: {
        config: {
            btns: [
                ['viewHTML'],
                ['undo', 'redo'], // Only supported in Blink browsers
                ['fontsize', 'formatting'],
                ['foreColor', 'colors', 'strong', 'em', 'del'],
                ['superscript', 'subscript'],
                ['link'],
                ['insertImage'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['horizontalRule'],
                ['removeformat'],
                ['fullscreen']
            ]
        }
    },
    hostnameCase: {
        '192.168.0.119': '192.168.0.89',
        'marketplace.cel.ro': 'api-mp.cel.ro',
        'demo.cel.ro': 'api-mp.cel.ro'
    },
    loadingExceptions: ['getNotifications', 'getChatMessages', 'getLatestNotifications'],
    cancelExceptions: ['getAffiliateData', 'getLatestNotifications', 'getAccountRestrictions', 'getBadges'],
    expandProductsList: Cookies.get('expandProductsList') == null || Cookies.get('expandProductsList') === 'true',
    imageBase: 'https://s1.cel.ro/images/',
    siteBase: 'https://cel.ro/',
    languages: [],
    charactersLimit: {
        productName: 140,
        characteristics: 80
    }
});

const mutations = {
    ...mutationsFile,
    ...make.mutations(initialState())
};

const getters = {
    ...gettersFile,
    ...make.getters(initialState())
};

export function createAppStore() {
    return {
        namespaced: true,
        state: initialState,
        actions,
        getters,
        mutations
    };
}

export default {
	sidebar: (state) => state.sidebar,
	device: (state) => state.device,
	badgesState: (state) => state.badges,
	loading: (state) => state.bodyLoading,
	appFocus: (state) => state.hasFocus,
	couriers: (state) => state.deliveryCouriers,
	currentLang: (state) => state.lang,
	fqGetter: (state) => state.queryParams,
	supplierCategories: (state) => state.supplierCategories,
	alreadyCalling: (state) => state.alreadyCalling,
	videoInstructionsActive: (state) => state.videoInstructions.active,
	videoInstructionsLink: (state) => state.videoInstructions.link,
	appVersion: (state) => state.packageVersion,
	fp: (state) => state.fp,
	fpComponents: (state) => state.fpComponents
};
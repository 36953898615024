const Layout = () => import('@/views/layout/Layout');
const ReclamatiiPage = () => import('@/views/comunicare/complaints/index');
const IntrebariPage = () => import('@/views/comunicare/questions/index');
const ServicePage = () => import('@/views/comunicare/service/index');
const ReturPage = () => import('@/views/comunicare/return/index');
const ServiceSinglePage = () => import('@/views/comunicare/service/single');
const ReturSinglePage = () => import('@/views/comunicare/return/single');
const IntrebariSinglePage = () => import('@/views/comunicare/questions/single');
const EvaluarePage = () => import('@/views/comunicare/evaluare_clienti');
const FeedbackPage = () => import('@/views/comunicare/feedback_comenzi');
const OrderContactPage = () => import('@/views/comunicare/order_contact');
export default [
    {
        path: '/comunicare',
        component: Layout,
        redirect: '/comunicare/intrebari',
        name: 'Comunicare',
        meta: {
            name: 'Comunicare',
            icon: 'email',
            tour: false,
            order: 6,
            title: 'communication-main',
            badge: [
                'intrebari',
                'service',
                'retur',
                'feedback_comenzi',
                'orders_contacts',
                'clients_feedback',
                'complaints'
            ],
            demoRestricted: false,
            module: 'communication'
        },
        children: [
            {
                path: 'reclamatii',
                name: 'Reclamatii',
                component: ReclamatiiPage,
                meta: {
                    tour: false,
                    badge: 'complaints',
                    title: 'communication-complaints',
                    icon: 'exclamation',
                    demoRestricted: false,
                    module: 'communication.communication-complaints'
                }
            },
            {
                path: 'intrebari',
                name: 'Intrebari',
                component: IntrebariPage,
                meta: {
                    tour: false,
                    badge: 'intrebari',
                    title: 'communication-questions',
                    icon: 'question',
                    demoRestricted: false,
                    module: 'communication.communication-questions'
                }
            },
            {
                path: 'service',
                name: 'Service',
                component: ServicePage,
                meta: {
                    badge: 'service',
                    icon: 'box',
                    tour: false,
                    title: 'communication-service',
                    demoRestricted: false,
                    module: 'communication.communication-service'
                },
                props: (route) => ({
                    filterQuery: {
                        minDate: route.query.minDate,
                        maxDate: route.query.maxDate,
                        products_model: route.query.products_model,
                        solved: route.query.solved
                    }
                })
            },
            {
                path: 'retur',
                name: 'Retur',
                component: ReturPage,
                meta: {
                    badge: 'retur',
                    tour: false,
                    icon: 'undo2',
                    title: 'communication-return',
                    demoRestricted: false,
                    module: 'communication.communication-return'
                },
                props: (route) => ({
                    filterQuery: {
                        minDate: route.query.minDate,
                        maxDate: route.query.maxDate,
                        products_model: route.query.products_model,
                        solved: route.query.solved
                    }
                })
            },
            {
                path: 'service_produs/:sid',
                name: 'Service Produs',
                props: true,
                component: ServiceSinglePage,
                meta: {
                    tour: false,
                    title: 'communication-service-single',
                    hidden: true,
                    module: 'communication.communication-service'
                }
            },
            {
                path: 'retur_produs/:rid',
                name: 'Retur Produs',
                props: true,
                component: ReturSinglePage,
                meta: {
                    tour: false,
                    title: 'communication-return-single',
                    hidden: true,
                    module: 'communication.communication-return'
                }
            },
            {
                path: 'intrebare_produs/:iid',
                name: 'Intrebare Produs',
                props: true,
                component: IntrebariSinglePage,
                meta: {
                    tour: false,
                    title: 'communication-questions-single',
                    hidden: true,
                    module: 'communication.communication-questions'
                }
            },
            {
                path: 'evaluare_clienti',
                name: 'Evaluare clienti',
                component: EvaluarePage,
                meta: {
                    badge: 'clients_feedback',
                    tour: false,
                    title: 'communication-client-eval',
                    hidden: false,
                    icon: 'bubble-2',
                    module: 'communication.communication-eval'
                },
                props: (route) => ({
                    filterQuery: {
                        from: route.query.from,
                        to: route.query.to,
                        comments: route.query.comments,
                        start: route.query.start,
                        evaltype: route.query.evaltype
                    }
                })
            },
            {
                path: 'feedback_comenzi',
                name: 'Feedback',
                component: FeedbackPage,
                meta: {
                    badge: 'feedback_comenzi',
                    tour: false,
                    title: 'communication-feedback',
                    hidden: false,
                    icon: 'bubbles3',
                    module: 'communication.communication-feedback'
                }
            },
            {
                path: 'order_contact',
                name: 'Contact comenzi',
                component: OrderContactPage,
                meta: {
                    badge: 'orders_contacts',
                    tour: false,
                    title: 'communication-orderscontact',
                    hidden: false,
                    icon: 'briefcase',
                    demoRestricted: false,
                    module: 'communication.communication-ordercontact'
                }
            }
        ]
    }
];

export default {
	info(message, data = '') {
		if (this.prototype.$env !== 'production') console.log('%c[INFO]', 'color:Blue', message, data);
	},
	warn(message, data = '') {
		if (this.prototype.$env !== 'production') console.log('%c[WARN]', 'color:Orange', message, data);
	},
	error(message, data = '') {
		if (this.prototype.$env !== 'production') console.log('%c[ERR]', 'color:Red', message, data);
	}
};
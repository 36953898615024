// translate router.meta.title, be used in breadcrumb sidebar tagsview
import i18n from '@/lang';
export function generateTitle(title, appendSite = false) {
	const hasKey = i18n.te('route.' + title);
	const translatedTitle = i18n.t('route.' + title) + (appendSite ? ' | CEL.ro Marketplace' : ''); // $t :this method from vue-i18n, inject in @/lang/index.js

	if (hasKey) {
		return translatedTitle;
	}
	return title;
}
import fetch from '@/utils/fetch';

export function getAccountData() {
    return fetch({
        url: '/admininfo/getAccountInformation',
        method: 'post',
    });
}

export function updateAccountData(data) {
    return fetch({
        url: '/admininfo/updateAccountInformation',
        method: 'post',
        data: data,
    });
}

export function getAccountShippingPolicy() {
    return fetch({
        url: '/admininfo/getAccountShippingPolicy',
        method: 'post',
    });
}

export function setAccountShippingPolicy(data) {
    return fetch({
        url: '/admininfo/setAccountShippingPolicy',
        method: 'post',
        data: data,
    });
}

export function getCitiesAndCounties() {
    return fetch({
        url: '/admininfo/getCitiesAndCounties',
        method: 'post',
    });
}

export function getCounties() {
    return fetch({
        url: '/admininfo/getCounties',
        method: 'post',
    });
}

export function getCities(data) {
    return fetch({
        url: '/admininfo/getCities',
        method: 'post',
        data: data,
    });
}

export function listAddresses() {
    return fetch({
        url: '/admininfo/listAddresses',
        method: 'post',
    });
}

export function getCouriers(data) {
    return fetch({
        url: '/admininfo/getCouriers',
        method: 'post',
    });
}

export function editAddress(data) {
    return fetch({
        url: '/admininfo/editAddress',
        method: 'post',
        data: data,
    });
}

export function deleteAddress(data) {
    return fetch({
        url: '/admininfo/deleteAddress',
        method: 'post',
        data: data,
    });
}

// For /cont/taxa-transport
export function getTransportTax(data) {
    return fetch({
        url: '/admininfo/getTransportTax',
        method: 'post',
        data: data,
    });
}

export function deleteTransportTax(data) {
    return fetch({
        url: '/admininfo/deleteTransportTax',
        method: 'post',
        data: data,
    });
}

export function updateTransportTax(data) {
    return fetch({
        url: '/admininfo/updateTransportTax',
        method: 'post',
        data: data,
    });
}

export function updateDeliveryInformation(data) {
    return fetch({
        url: '/admininfo/updateDeliveryInformation',
        method: 'post',
        data: data,
    });
}

export function addCourier(data) {
    return fetch({
        url: '/admininfo/addCourier',
        method: 'post',
        data: data,
    });
}

export function getAffiliateData(data) {
    return fetch({
        url: '/admininfo/getAffiliateData',
        method: 'post',
        data,
    });
}

export function getMccCodes(data) {
    return fetch({
        url: '/admininfo/getMccCodes',
        method: 'post',
        data: data,
    });
}

export function getCurrentMcc(data) {
    return fetch({
        url: '/admininfo/getCurrentMcc',
        method: 'post',
        data: data,
    });
}

export function addMccCode(data) {
    return fetch({
        url: '/admininfo/addMccCode',
        method: 'post',
        data: data,
    });
}

export function removeMccCode(data) {
    return fetch({
        url: '/admininfo/removeMccCode',
        method: 'post',
        data: data,
    });
}

export function getInformationCombined(data) {
    return fetch({
        url: '/admininfo/getInformationCombined',
        method: 'post',
        data: data,
    });
}

export function getAllCategories(data) {
    return fetch({
        url: '/admininfo/getAllCategories',
        method: 'post',
        data: data,
    });
}

export function getCategoriesForAccess(data) {
    return fetch({
        url: '/admininfo/getCategoriesForAccess',
        method: 'post',
        data: data,
    });
}

export function getCategoriesAccess(data) {
    return fetch({
        url: '/admininfo/getCategoriesAccess',
        method: 'post',
        data: data,
    });
}

export function requestCategoryAccess(data) {
    return fetch({
        url: '/admininfo/requestCategoryAccess',
        method: 'post',
        data: data,
    });
}

export function subscribeToApiNewsletter(data) {
    return fetch({
        url: '/admininfo/subscribeToApiNewsletter',
        method: 'post',
        data: data,
    });
}

export function subscribeToSMSOrderNotifications(data) {
    return fetch({
        url: '/admininfo/subscribeToSMSOrderNotifications',
        method: 'post',
        data: data,
    });
}

export function removeSubscriptionToSMSOrderNotifications(data) {
    return fetch({
        url: '/admininfo/removeSubscriptionToSMSOrderNotifications',
        method: 'post',
        data: data,
    });
}

export function removeSubscriptionToApiNewsletter(data) {
    return fetch({
        url: '/admininfo/removeSubscriptionToApiNewsletter',
        method: 'post',
        data: data,
    });
}

export function getApiNewsletterSubscriptionStatus(data) {
    return fetch({
        url: '/admininfo/getApiNewsletterSubscriptionStatus',
        method: 'post',
        data: data,
    });
}

export function getSMSOrdersSubscriptionStatus() {
    return fetch({
        url: '/admininfo/getSMSOrdersSubscriptionStatus',
        method: 'post',
    });
}

export function getDocuments() {
    return fetch({
        url: '/admininfo/getDocuments',
        method: 'post',
    });
}

export function getDocData(data) {
    return fetch({
        url: '/admininfo/getDocData',
        method: 'post',
        data: data,
    });
}

export function approveDoc(data) {
    return fetch({
        url: '/admininfo/approveDoc',
        method: 'post',
        data: data,
    });
}

export function viewDoc(data) {
    return fetch({
        url: '/admininfo/viewDoc',
        method: 'post',
        data: data,
    });
}

export function reportDoc(data) {
    return fetch({
        url: '/admininfo/reportDoc',
        method: 'post',
        data: data,
    });
}

export function downloadUnsignedDoc(data) {
    return fetch({
        url: '/admininfo/downloadUnsignedDoc',
        method: 'post',
        data: data,
    });
}

export function downloadUploadedFile(data) {
    return fetch({
        url: '/admininfo/downloadUploadedFile',
        method: 'post',
        data: data,
    });
}

export function uploadDocFile(data) {
    return fetch({
        url: '/admininfo/uploadDocFile',
        method: 'post',
        data: data,
    });
}

export function getSmartBillStatus() {
    return fetch({
        url: '/admininfo/getSmartBillStatus',
        method: 'post',
    });
}

export function submitSmartBillData(data) {
    return fetch({
        url: '/admininfo/submitSmartBillData',
        method: 'post',
        data: data,
    });
}

export function checkSmartBillAccount(data) {
    return fetch({
        url: '/admininfo/checkSmartBillAccount',
        method: 'post',
        data: data,
    });
}

export function sendFeedback(data) {
    return fetch({
        url: '/admininfo/sendFeedback',
        method: 'post',
        data: data,
    });
}

export function changeHolidayStatus(data) {
    return fetch({
        url: '/admininfo/changeHolidayStatus',
        method: 'post',
        data: data,
    });
}

export function changeAllowCreditCard(data) {
    return fetch({
        url: '/admininfo/changeAllowCreditCard',
        method: 'post',
        data: data,
    });
}

export function checkMandatoryInfo() {
    return fetch({
        url: '/admininfo/checkMandatoryInfo',
        method: 'post',
    });
}

export function closeAccount() {
    return fetch({
        url: '/admininfo/closeAccount',
        method: 'post',
    });
}

export function getContact() {
    return fetch({
        url: '/admininfo/getContact',
        method: 'post',
    });
}

export function getPagesWithContractBreaches(data) {
    return fetch({
        url: '/admininfo/getPagesWithContractBreaches',
        method: 'post',
        data: data,
    });
}

export function saveAffiliateLink(data) {
    return fetch({
        url: '/admininfo/saveAffiliateLink',
        method: 'post',
        data: data,
    });
}

export function checkSiteUrl() {
    return fetch({
        url: '/admininfo/checkSiteUrl',
        method: 'post',
    });
}

export function getEmailAddresses() {
    return fetch({
        url: '/admininfo/getEmailAddresses',
        method: 'post',
    });
}

export function setEmailAddresses(data) {
    return fetch({
        url: '/admininfo/setEmailAddresses',
        method: 'post',
        data: data,
    });
}

export function updateNotificationAssoc(data) {
    return fetch({
        url: '/admininfo/updateNotificationAssoc',
        method: 'post',
        data,
    });
}

export function getNotificationsList() {
    return fetch({
        url: '/account/getNotificationsList',
        method: 'post',
    });
}

export function getNotificationsAssoc(data) {
    return fetch({
        url: '/account/getNotificationsAssoc',
        method: 'post',
        data: data,
    });
}

export function addNotificationAssoc(data) {
    return fetch({
        url: '/account/addNotificationAssoc',
        method: 'post',
        data,
    });
}

export function deleteNotificationAssoc(data) {
    return fetch({
        url: '/account/deleteNotificationAssoc',
        method: 'post',
        data,
    });
}

export function uploadAffiliateActs(data) {
    return fetch({
        url: '/admininfo/uploadAffiliateActs',
        method: 'post',
        data,
    });
}

export function getAffiliateActsList() {
    return fetch({
        url: '/admininfo/getAffiliateActsList',
        method: 'post',
    });
}

export function addAffiliateActs(data) {
    return fetch({
        url: '/admininfo/addAffiliateActs',
        method: 'post',
        data,
    });
}

// /cont/informatii/profile
export function getProfile() {
    return fetch({
        url: '/admininfo/getAffiliateProfile',
        method: 'post',
    });
}

export function getGoMag() {
    return fetch({
        url: '/admininfo/getAfiliateGoMag',
        method: 'post',
    });
}

export function saveProfile(data) {
    return fetch({
        url: '/admininfo/saveAffiliateProfile',
        method: 'post',
        data,
    });
}

export function getAffiliateContacts(data) {
    return fetch({
        url: '/admininfo/getAffiliateContacts',
        method: 'post',
        data,
    });
}

export function saveAffiliateContact(data) {
    return fetch({
        url: '/admininfo/saveAfiliateContact',
        method: 'post',
        data,
    });
}

export function getAffiliateFinancial() {
    return fetch({
        url: '/admininfo/getAffiliateFinancial',
        method: 'post',
    });
}

export function saveAffiliateFinancial(data) {
    return fetch({
        url: '/admininfo/saveAffiliateFinancial',
        method: 'post',
        data,
    });
}

export function getTechincalData() {
    return fetch({
        url: '/admininfo/getTechincalData',
        method: 'post',
    });
}

export function saveTechnicalData(data) {
    return fetch({
        url: '/admininfo/saveTechnicalData',
        method: 'post',
        data,
    });
}

export function getAffiliateMCCData(data) {
    return fetch({
        url: '/admininfo/getAffiliateMCCData',
        method: 'post',
        data,
    });
}

export function getTerminalDocStatus(data) {
    return fetch({
        url: '/admininfo/getTerminalDocStatus',
        method: 'post',
    });
}

export function changeAffiliatePassword(data) {
    return fetch({
        url: '/admininfo/changeAffiliatePassword',
        method: 'post',
        data,
    });
}

export function getAffiliateActs() {
    return fetch({
        url: '/admininfo/getAffiliateActs',
        method: 'post',
    });
}

export function uploadActFile(data) {
    return fetch({
        url: '/admininfo/uploadActFile',
        method: 'post',
        data,
    });
}

export function removeActFile(data) {
    return fetch({
        url: '/admininfo/removeActFile',
        method: 'post',
        data,
    });
}

export function downloadUploadedAct(data) {
    return fetch({
        url: '/admininfo/downloadUploadedAct',
        method: 'post',
        data,
    });
}

export function getAffiliateDemoProgress() {
    return fetch({
        url: '/admininfo/getAffiliateDemoProgress',
        method: 'post',
    });
}

export function getAccountCouriers(data) {
    return fetch({
        url: '/admininfo/getAccountCouriers',
        method: 'post',
        data,
    });
}

export function getCouriersBoxes(data) {
    return fetch({
        url: '/admininfo/getCouriersBoxes',
        method: 'post',
        data,
    });
}

export function getCourierAccount(data) {
    return fetch({
        url: '/admininfo/getCourierAccount',
        method: 'post',
        data,
    });
}

export function updateCourierPickupPoints(data) {
    return fetch({
        url: '/admininfo/updateCourierPickupPoints',
        method: 'post',
        data,
    });
}

export function deleteCourierAccount(data) {
    return fetch({
        url: '/admininfo/deleteCourierAccount',
        method: 'post',
        data,
    });
}

export function addAccountCourier(data) {
    return fetch({
        url: '/admininfo/addAccountCourier',
        method: 'post',
        data,
    });
}

export function getAffiliateAddresses(data) {
    return fetch({
        url: '/admininfo/getAffiliateAddresses',
        method: 'post',
        data,
    });
}

export function getAffiliateAddress(data) {
    return fetch({
        url: '/admininfo/getAffiliateAddress',
        method: 'post',
        data,
    });
}

export function saveAffiliateAddress(data) {
    return fetch({
        url: '/admininfo/saveAffiliateAddress',
        method: 'post',
        data,
    });
}

export function getCountriesList() {
    return fetch({
        url: '/admininfo/getCountriesList',
        method: 'post',
    });
}

export function getCountiesList() {
    return fetch({
        url: '/admininfo/getCountiesList',
        method: 'post',
    });
}

export function getCitiesList(data) {
    return fetch({
        url: '/admininfo/getCitiesList',
        method: 'post',
        data: data,
    });
}

export function setCourierAccountFavorite(data) {
    return fetch({
        url: '/admininfo/setCourierAccountFavorite',
        method: 'post',
        data: data,
    });
}

export function setAffiliateAddressFavorite(data) {
    return fetch({
        url: '/admininfo/setAffiliateAddressFavorite',
        method: 'post',
        data,
    });
}

export function approveGDPR() {
    return fetch({
        url: '/admininfo/approveGDPR',
        method: 'post',
    });
}

export function getReamingDemoDays() {
    return fetch({
        url: '/admininfo/getReamingDemoDays',
        method: 'post',
    });
}

export function getChestionareDisponibile() {
    return fetch({
        url: '/admininfo/getChestionareDisponibile',
        method: 'post',
    });
}

export function sendDataChestionare(data) {
    return fetch({
        url: '/admininfo/sendDataChestionare',
        method: 'post',
        data: {
            data: data.chestionare,
            custom: data.customChestionare,
        },
    });
}

export function getBillingData(data) {
    return fetch({
        url: '/admininfo/getBillingData',
        method: 'post',
    });
}

export function saveBillingData(data) {
    return fetch({
        url: '/admininfo/saveBillingData',
        method: 'post',
        data,
    });
}

export function approveTOS() {
    return fetch({
        url: '/admininfo/approveTOS',
        method: 'post',
    });
}

export function getDateCUI(data) {
    return fetch({
        url: '/admininfo/getDateCUI',
        method: 'post',
        data,
    });
}

export function getRestrictionEntities(data) {
    return fetch({
        url: '/admininfo/getRestrictionEntities',
        method: 'post',
        data,
    });
}
export function downloadCommissionsList() {
    return fetch({
        url: '/admininfo/downloadCommissionsList',
        method: 'post',
    });
}

export function getCourierSettings(data) {
    return fetch({
        url: '/admininfo/getCourierSettings',
        method: 'post',
        data,
    });
}

import fetch from '@/utils/fetch';

export function login(username, password, remember) {
	return fetch({
		url: '/login/actionLogin',
		method: 'post',
		data: {
			username,
			password,
			remember
		}
	});
}

export function getInfo() {
	return fetch({
		url: '/admininfo/getAffiliateInfo',
		method: 'post',
	});
}


export function resetPassword(data) {
	return fetch({
		url: '/login/resetPassword',
		method: 'post',
		data
	});
}

export function requestPasswordReset(data) {
	return fetch({
		url: '/login/requestPasswordReset',
		method: 'post',
		data
	});
}

export function getAccountRestrictions() {
	return fetch({
		url: '/admininfo/getAccountRestrictions',
		method: 'post',
	});
}

export function recheckRestriction(data) {
	return fetch({
		url: '/admininfo/recheckRestriction',
		method: 'post',
		data
	});
}

export function logout() {
	return fetch({
		url: '/admininfo/logout',
		method: 'post',
	});
}
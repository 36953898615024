import {
	initialState
} from './index';

export default {
	TOGGLE_NOTIFICATIONS: (state) => {
		state.opened = !state.opened;
	},
	SET_NOTIFICATIONS: (state, data) => {
		state.list = data;
	},
	PREPEND_NOTIFICATION: (state, notification) => {
		state.list = [notification].concat(state.list);
	},
	MARK_IDS_AS_READ: (state, ids) => {
		for (let i = 0, l = state.list.length; i < l; i++) {
			const currentNotification = state.list[i];
			if (!ids.includes(currentNotification.id)) continue;
			state.list[i].seen = 1;
		}
	},
	SET_NOTIFICAITON_ALERT: (state, status) => {
		state.alert = status;
	},
	SET_LOADING: (state, status) => {
		state.loading = status;
	},
	SET_LIST_START: (state, value) => {
		state.start = value;
	},
	ADD_NOTIFICATIONS_TO_LIST: (state, list) => {
		state.list = state.list.concat(list);
	},
	SET_FINISHED_LIST: (state, value) => {
		state.finishedList = value;
	},
	SET_NOTIFICATIONS_PERMISSIONS: (state, status) => {
		state.permission = status;
	},
	SET_NOTIFICATIONS_BAR: (state, status) => {
		state.bar = status;
	},
	RESET_STATE: (state) => {
		const iState = initialState();
		for (const prop in state) {
			if (typeof iState[prop] !== 'undefined' && prop !== 'token') {
				state[prop] = iState[prop];
			}
		}
	},
};
import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import orderBy from 'lodash.sortby';
window.VueRouter = VueRouter;

/* layout */
const Layout = () => import('@/views/layout/Layout');
const LoginPage = () => import('@/views/login/index');
const ForgotPasswordPage = () => import('@/views/login/forgot-password');
const RegisterPage = () => import('@/views/register/index');
const NotFoundPage = () => import('@/views/404');
const LeadPage = () => import('@/views/lead');
const DashboardPage = () => import('@/views/dashboard/index');
const SurveyPage = () => import('@/views/survey');

// const MaintenanceMode = () => import('@/views/MaintenanceMode');
// Import all router modules
import * as dynamicRoutes from '@/router/modules';
Vue.use(VueRouter);
Vue.use(Meta);

/**
 * icon : the icon show in the sidebar
 * hidden : if `hidden:true` will not show in the sidebar
 * redirect : if `redirect:noredirect` will not redirct in the levelbar
 * noDropdown : if `noDropdown:true` will not has submenu in the sidebar
 * meta : `{ role: ['admin'] }`  will control the page role
 **/
let baseRoutes = [
    // {
    // 	path: '/*',
    // 	component: MaintenanceMode,
    // 	name: 'Maintenance',
    // 	meta: {
    // 		noAuth: true,
    // 		authRedirect: false,
    // 		tour: false,
    // 		hidden: true,
    // 	}
    // },
    {
        path: '/login',
        component: LoginPage,
        name: 'Login',
        meta: {
            title: 'login',
            noAuth: true,
            authRedirect: false,
            tour: false,
            hidden: true
        },
        props(route) {
            return route.query || {};
        }
    },
    {
        path: '/forgot-password',
        component: ForgotPasswordPage,
        name: 'ForgotPassword',
        meta: {
            title: 'forgot-password',
            noAuth: true,
            authRedirect: true,
            tour: false,
            hidden: true
        },
        props: (route) => ({
            token: route.query.token
        })
    },
    {
        path: '/register/:token',
        component: RegisterPage,
        name: 'register',
        props: true,
        meta: {
            title: 'register',
            noAuth: true,
            authRedirect: true,
            tour: false,
            hidden: true
        }
    },
    {
        path: '/404',
        component: NotFoundPage,
        name: 'NotFound',
        meta: {
            title: 'notfound',
            noAuth: true,
            authRedirect: true,
            tour: false,
            hidden: true
        }
    },
    {
        path: '/',
        name: 'Lead',
        meta: {
            noAuth: true,
            authRedirect: true,
            tour: false,
            hidden: true
        },
        component: LeadPage
    },
    {
        path: '/dashboard',
        component: Layout,
        redirect: '/dashboard/index',
        name: 'DashboardParent',
        meta: {
            tour: false,
            hidden: true,
            title: 'dashboard-main',
            icon: 'briefcase',
            demoRestricted: false,
            module: 'dashboard'
        },
        children: [
            {
                path: 'index',
                name: 'Dashboard',
                component: DashboardPage,
                meta: {
                    tour: false,
                    hidden: true,
                    title: 'dashboard-main',
                    icon: 'briefcase',
                    demoRestricted: true,
                    module: 'dashboard'
                }
            }
        ]
    },
    {
        path: '/survey/:id',
        meta: {
            title: 'survey',
            noAuth: true,
            tour: false,
            hidden: true
        },
        props: true,
        name: 'survey',
        component: SurveyPage
    },

    // {
    //     path: '/faq',
    //     component: Layout,
    //     redirect: '/faq/index',
    //     icon: 'question',
    //     noDropdown: true,
    //     children: [{
    //         path: 'index',
    //         name: 'F.A.Q',
    //         component: _import('faq/index')
    //     }]
    // },

    {
        path: '*',
        redirect: '/404',
        name: 'NotFoundRedirect',
        meta: {
            title: 'notfound',
            noAuth: true,
            authRedirect: true,
            tour: false,
            hidden: true
        }
    }
];

for (const i in dynamicRoutes) {
    if (typeof dynamicRoutes[i] !== 'undefined') {
        baseRoutes = baseRoutes.concat(dynamicRoutes[i]);
    }
}

export default new VueRouter({
    mode: 'history',
    scrollBehavior: () => ({
        y: 0
    }),
    routes: orderBy(baseRoutes, (e) => {
        return e.meta.order;
    })
});

<template>
    <div id="app">
        <Snackbar></Snackbar>
        <router-view></router-view>
    </div>
</template>

<script>
const Snackbar = () => import('./components/Snackbar');
export default {
    name: 'app',
    components: {
        Snackbar
    },
    methods: {},
    async mounted() {
        console.log(this.$store.getters['app/appVersion']);
    }
};
</script>
